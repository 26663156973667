import {
  SVG_BELL_FILLED,
  SVG_BELL_OUTLINED,
  SVG_CALENDAR_FILLED,
  SVG_CALENDAR_OUTLINED,
  SVG_CLOCK_FILLED,
  SVG_CLOCK_OUTLINED,
  SVG_FINE_ADJUSTMENT_FILLED,
  SVG_FINE_ADJUSTMENT_OUTLINED,
  SVG_FONT_OUTLINED,
  SVG_KEYBOARD_OUTLINED,
  SVG_VISIBILITY_ON_FILLED,
  SVG_VISIBILITY_ON_OUTLINED,
} from '@getgo/chameleon-icons'
import type { NavigationLink } from '../common/global-props'
import {
  APPEARANCE_SETTINGS_ROUTE,
  CALENDAR_SETTINGS_ROUTE,
  KEYBOARD_SHORTCUT_SETTINGS_ROUTE,
  LANGUAGE_SETTINGS_ROUTE,
  NOTIFICATIONS_SETTINGS_ROUTE,
  SCHEDULE_SETTINGS_ROUTE,
  SHELL_SETTINGS_APP_LAUNCH_ROUTE,
} from '../common/routes'
import { isContainer } from '../services/container/helpers'
import { getFeatureFlagValue } from '../services/feature-flags'
import { FeatureFlagsVariations } from '../services/feature-flags/models'
import { asTranslationKey } from '../common/translate-helpers/i18n-utils'
import { getShellApiInstance } from '../common/shell-api-helpers'
import { getExternalInterface } from '../services/external-interface'
import { isGoToMeetingCustomer } from '../services/identity/entitlement'

export const gotoAppLaunchSettingsLink: NavigationLink = {
  id: '@settings/app-launch',
  href: SHELL_SETTINGS_APP_LAUNCH_ROUTE,
  isVisible: /* istanbul ignore next not useful for unit test */ async () => isContainer(),
  name: asTranslationKey('App launch'),
  icon: {
    active: SVG_FINE_ADJUSTMENT_FILLED,
    inactive: SVG_FINE_ADJUSTMENT_OUTLINED,
  },
}

export const gotoCalendarSettingsLink: NavigationLink = {
  id: '@settings/calendar',
  href: CALENDAR_SETTINGS_ROUTE,
  isVisible: /* istanbul ignore next not useful for unit test */ async () => !!getShellApiInstance().user.key,
  name: asTranslationKey('Calendar'),
  icon: {
    active: SVG_CALENDAR_FILLED,
    inactive: SVG_CALENDAR_OUTLINED,
  },
}

export const gotoAppearanceSettingsLink: NavigationLink = {
  id: '@settings/appearance',
  href: APPEARANCE_SETTINGS_ROUTE,
  isVisible: /* istanbul ignore next not useful for unit test */ async () =>
    !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_ENABLE_DARKMODE) && !getExternalInterface().isIntegration,
  name: asTranslationKey('Appearance'),
  icon: {
    active: SVG_VISIBILITY_ON_FILLED,
    inactive: SVG_VISIBILITY_ON_OUTLINED,
  },
}

export const scheduleSettingsName = asTranslationKey('Schedule')

export const gotoScheduleSettingsLink: NavigationLink = {
  id: '@settings/schedule',
  href: SCHEDULE_SETTINGS_ROUTE,
  isVisible: /* istanbul ignore next not useful for unit test */ async () =>
    !isGoToMeetingCustomer() && !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_SCHEDULE_SETTINGS),
  name: scheduleSettingsName,
  icon: {
    active: SVG_CLOCK_FILLED,
    inactive: SVG_CLOCK_OUTLINED,
  },
}

export const gotoHotkeySettingsLink: NavigationLink = {
  id: '@settings/hotkeys',
  href: KEYBOARD_SHORTCUT_SETTINGS_ROUTE,
  isVisible: /* istanbul ignore next not useful for unit test */ async () =>
    !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_HOTKEY_SETTINGS),
  name: asTranslationKey('Keyboard shortcuts'),
  icon: SVG_KEYBOARD_OUTLINED,
}

export const gotoNotificationsSettingsLink: NavigationLink = {
  id: '@settings/notifications',
  href: NOTIFICATIONS_SETTINGS_ROUTE,
  isVisible: /* istanbul ignore next not useful for unit test */ async () =>
    isContainer() && !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_NOTIFICATION_SETTINGS),
  name: asTranslationKey('Notifications'),
  icon: {
    active: SVG_BELL_FILLED,
    inactive: SVG_BELL_OUTLINED,
  },
}

export const gotoLanguageSettingsLink: NavigationLink = {
  id: '@settings/language',
  href: LANGUAGE_SETTINGS_ROUTE,
  isVisible: /* istanbul ignore next not useful for unit test */ async () =>
    !!getFeatureFlagValue(FeatureFlagsVariations.SHELL_LANGUAGE_SETTINGS),
  name: asTranslationKey('Language'),
  icon: SVG_FONT_OUTLINED,
}

/**
 * A list of general setting links that can be used in our components to display them. We can add more general settings when they become available
 */
export const generalSettingsLinks: readonly NavigationLink[] = [
  gotoAppLaunchSettingsLink,
  gotoCalendarSettingsLink,
  gotoAppearanceSettingsLink,
  gotoScheduleSettingsLink,
  gotoHotkeySettingsLink,
  gotoNotificationsSettingsLink,
  gotoLanguageSettingsLink,
]
