import { addContextMenuAction, getContextMenuContextItemValueByType } from '@goto/shell-utils'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { SVG_SEARCH_OUTLINED } from '@getgo/chameleon-icons'
import { executeSearch } from '../global-search/namespace'
import { trimString } from '../../common/helpers/string'
import { ShellActionCategorySearch, type ShellContextMenuEvent } from '@goto/shell-common'

/**
 * Adds search-related actions into a context menu event
 * @param event event in which to add search actions
 */
export const addSearchActions = (event: ShellContextMenuEvent) => {
  const str = getContextMenuContextItemValueByType<string>(event, 'string')
  if (str) {
    const searchAction = {
      label: getTranslation(`Search for "{{searchString}}"`, {
        searchString: trimString(str, 12, true),
      }),
      icon: SVG_SEARCH_OUTLINED,
      enabled: true,
      category: ShellActionCategorySearch,
      executeFunction: () => {
        executeSearch({ query: str })
      },
    }
    addContextMenuAction(event, searchAction)
  }
}
