import { ExtensionConfig, PrerequisiteRuleTypes } from '@goto/shell-common'
import { getFeatureFlag } from '../helpers/shell-helpers'

const extensionConfig: ExtensionConfig = {
  id: '@ucc-messaging/messaging-extension',
  get url() {
    const inboxVersion = getFeatureFlag('inbox-version', 'latest')
    return new URL(
      inboxVersion
        ? `/experiences/messaging/${inboxVersion}/messaging-extension.mjs`
        : `/experiences/messaging/messaging-extension.js`,
      location.origin,
    )
  },
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: 'jive || gotoconnect || g2m',
  },
  optionalDependencies: [
    '@goto/contacts-ui-extension',
    '@dce/engagements-web-components-loader-extension'
  ],
  esModule: true,
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
