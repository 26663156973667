import { html, type PropertyValues, state } from 'lit-element'
import notificationsSettingsStyles from './notifications-settings.styles.scss'
import { t } from '../../directives/translate'
import { setToLocalStorage } from '../../common/dom-helpers'
import { LocalStorageKeys } from '../../environments'
import type { CheckboxComponent, SwitchComponent } from '@getgo/chameleon-web'
import {
  getNotificationSettings,
  type NotificationSettings,
  updateNotificationSettings,
} from '../../services/notification-settings'
import { ShellElement } from '../../common/shell-element'

interface CheckboxInformation {
  readonly id: string
  readonly notification: string
  readonly category: string
}

const isCheckboxChecked = (checkbox: CheckboxComponent) => checkbox.checked

export class GoToNotificationsSettings extends ShellElement {
  static readonly tagName = 'goto-notifications-settings'

  @state() private notificationSettings: NotificationSettings = {
    general: {
      message: true,
      call: true,
      meeting: true,
    },
    doNotDisturb: {
      message: true,
      meeting: true,
    },
  }
  private switchTurnedOffFromCheckbox = false
  private switchTurnedOnFromCheckbox = false

  private readonly generalCheckboxArray: CheckboxInformation[] = [
    {
      id: '#general-message',
      notification: 'message',
      category: 'general',
    },
    {
      id: '#general-call',
      notification: 'call',
      category: 'general',
    },
    {
      id: '#general-meeting',
      notification: 'meeting',
      category: 'general',
    },
  ]

  private readonly dndCheckboxArray: CheckboxInformation[] = [
    {
      id: '#dnd-message',
      notification: 'message',
      category: 'dnd',
    },
    {
      id: '#dnd-meeting',
      notification: 'meeting',
      category: 'dnd',
    },
  ]

  static get styles() {
    return notificationsSettingsStyles
  }

  /* istanbul ignore next this function exists for testing purposes only */
  public getLocalNotificationSettings() {
    return this.notificationSettings
  }

  firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties)
    this.notificationSettings = getNotificationSettings()
    this.updateCheckboxes()
    this.handleSwitch()
    this.handleCheckboxes()
    this.toggleSwitchOn()
  }

  private readonly handleSwitch = () => {
    const notificationsSwitch = this.shadowRoot?.querySelector('.general-switch')
    if (!notificationsSwitch) {
      return
    }
    notificationsSwitch.addEventListener('change', this.handleSwitchEventListener)
    this.unsubscribeFunctions.push(() => {
      notificationsSwitch.removeEventListener('change', this.handleSwitchEventListener)
    })
  }

  private readonly handleSwitchEventListener = (event: Event) => {
    if ((event.target as SwitchComponent).checked) {
      if (this.switchTurnedOnFromCheckbox) {
        this.switchTurnedOnFromCheckbox = false
      } else {
        this.setGeneralSettings(true)
      }
    } else {
      if (this.switchTurnedOffFromCheckbox) {
        this.switchTurnedOffFromCheckbox = false
      } else {
        this.setGeneralSettings(false)
      }
    }
  }

  private readonly setGeneralSettings = (showNotification: boolean) => {
    this.notificationSettings.general.message = showNotification
    this.notificationSettings.general.call = showNotification
    this.notificationSettings.general.meeting = showNotification
    this.setNotificationSettingsInLocalStorage()
    this.updateCheckboxes()
    updateNotificationSettings(this.notificationSettings)
  }

  private readonly handleCheckboxes = () => {
    this.generalCheckboxArray.forEach(checkbox => {
      const checkboxEl = this.shadowRoot?.querySelector(checkbox.id)
      if (!checkboxEl) {
        return
      }
      const listener = (event: Event) => this.generalCheckboxEvent(event.target as CheckboxComponent, checkbox)
      checkboxEl.addEventListener('change', listener)
      this.unsubscribeFunctions.push(() => checkboxEl.removeEventListener('change', listener))
    })
    this.dndCheckboxArray.forEach(checkbox => {
      const checkboxEl = this.shadowRoot?.querySelector(checkbox.id)
      if (!checkboxEl) {
        return
      }
      const listener = (event: Event) => this.dndCheckboxEvent(event.target as CheckboxComponent, checkbox)
      checkboxEl.addEventListener('change', listener)
      this.unsubscribeFunctions.push(() => checkboxEl.removeEventListener('change', listener))
    })
  }

  private generalCheckboxEvent(checkboxComponent: CheckboxComponent, checkbox: CheckboxInformation) {
    const isChecked = isCheckboxChecked(checkboxComponent)
    this.notificationSettings.general[checkbox.notification] = isChecked
    if (isChecked) {
      this.toggleSwitchOn()
    } else {
      this.toggleSwitchOff()
    }
    this.setNotificationSettingsInLocalStorage()
    updateNotificationSettings(this.notificationSettings)
  }

  private dndCheckboxEvent(checkboxComponent: CheckboxComponent, checkbox: CheckboxInformation) {
    const isChecked = isCheckboxChecked(checkboxComponent)
    this.notificationSettings.doNotDisturb[checkbox.notification] = isChecked
    this.setNotificationSettingsInLocalStorage()
    updateNotificationSettings(this.notificationSettings)
  }

  private updateCheckboxes() {
    const getCheckboxComponent = (id: string) => this.shadowRoot?.querySelector<CheckboxComponent>(id)

    this.generalCheckboxArray.forEach(checkbox => {
      const checkboxComponent = getCheckboxComponent(checkbox.id)
      if (checkboxComponent) {
        checkboxComponent.checked = this.notificationSettings.general[checkbox.notification]
      }
    })

    this.dndCheckboxArray.forEach(checkbox => {
      const checkboxComponent = getCheckboxComponent(checkbox.id)
      if (checkboxComponent) {
        checkboxComponent.checked = this.notificationSettings.doNotDisturb[checkbox.notification]
      }
    })
  }

  private toggleSwitchOff() {
    const notificationsSwitch = this.shadowRoot?.querySelector('.general-switch') as SwitchComponent
    if (notificationsSwitch.checked) {
      this.switchTurnedOffFromCheckbox = true
      notificationsSwitch.checked = false
    }
  }

  private toggleSwitchOn() {
    const notificationsSwitch = this.shadowRoot?.querySelector('.general-switch') as SwitchComponent
    const notificationCheckboxes = Array.from(
      this.shadowRoot?.querySelectorAll('.general-checkboxes chameleon-checkbox') ?? [],
    )

    const isChecked = (checkbox: CheckboxComponent) => checkbox.checked

    if (!notificationsSwitch.checked && notificationCheckboxes.length && notificationCheckboxes.every(isChecked)) {
      this.switchTurnedOnFromCheckbox = true
      notificationsSwitch.checked = true
    }
  }

  private setNotificationSettingsInLocalStorage() {
    setToLocalStorage(LocalStorageKeys.notificationSettings, JSON.stringify(this.notificationSettings))
  }

  render() {
    return html`
      <chameleon-typography class="notifications-header" tag="h1" variant="heading-large">
        ${t('Notifications')}
      </chameleon-typography>
      <chameleon-typography class="header-description" tag="p" variant="body-medium">
        ${t('Decide which notifications to show and when, depending on how busy you are.')}
      </chameleon-typography>
      <div class="notifications-content">
        <chameleon-typography tag="p" variant="heading-small">${t('General')}</chameleon-typography>
        <div class="switch-block">
          <div class="switch-text-block">
            <chameleon-typography class="content-headers" tag="p" variant="body-large">
              ${t('Show all')}
            </chameleon-typography>
            <chameleon-typography
              class="content-description"
              tag="p"
              variant="caption-medium"
              color="type-color-secondary"
            >
              ${t('Receive in-app banner notifications for messages, calls, and meeting reminders.')}
            </chameleon-typography>
            <chameleon-typography class="content-headers" tag="p" variant="body-large">
              ${t('Customize')}
            </chameleon-typography>
            <chameleon-typography
              class="content-description"
              tag="p"
              variant="caption-medium"
              color="type-color-secondary"
            >
              ${t('Choose what you would like to receive in-app banner notifications for.')}
            </chameleon-typography>
            <div class="general-checkboxes">
              <chameleon-checkbox class="checkbox" id="general-message" size="large">
                ${t('New message')}
              </chameleon-checkbox>
              <chameleon-checkbox class="checkbox" id="general-call" size="large">${t('New call')}</chameleon-checkbox>
              <chameleon-checkbox class="checkbox" id="general-meeting" size="large">
                ${t('Meeting reminders')}
              </chameleon-checkbox>
            </div>
          </div>
          <chameleon-switch class="general-switch"></chameleon-switch>
        </div>
      </div>

      <div class="notifications-content">
        <chameleon-typography tag="p" variant="heading-small">${t('Do not disturb')}</chameleon-typography>
        <div class="switch-block">
          <div class="switch-text-block">
            <chameleon-typography class="content-headers" tag="p" variant="body-large">
              ${t('Customize')}
            </chameleon-typography>
            <chameleon-typography
              class="content-description"
              tag="p"
              variant="caption-medium"
              color="type-color-secondary"
            >
              ${t('Choose which notifications will still show in do not disturb mode.')}
            </chameleon-typography>
            <chameleon-typography
              class="content-description"
              tag="p"
              variant="caption-medium"
              color="type-color-secondary"
            >
              ${t('When Do Not Disturb is activated, calls will always be directed straight to voicemail.')}
            </chameleon-typography>
            <div class="dnd-checkboxes">
              <chameleon-checkbox class="checkbox" id="dnd-message" size="large"
                >${t('New message')}</chameleon-checkbox
              >
              <chameleon-checkbox class="checkbox" id="dnd-meeting" size="large">
                ${t('Meeting reminders')}
              </chameleon-checkbox>
            </div>
          </div>
        </div>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-notifications-settings': GoToNotificationsSettings
  }
}
