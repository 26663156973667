import { SVG_CLIPBOARD_OUTLINED, SVG_COPY_OUTLINED, SVG_CUT_OUTLINED } from '@getgo/chameleon-icons'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import {
  canCopyText,
  canCutText,
  canPasteText,
  copyText,
  cutText,
  getWindowSelectedText,
  isTextInput,
  pasteText,
} from './clipboard-utils'
import { addContextMenuAction } from '@goto/shell-utils'
import { type ShellAction, ShellActionCategoryClipboard, type ShellContextMenuEvent } from '@goto/shell-common'

/**
 * Adds clipboard-related actions into a context menu event
 * @param event event in which to add clipboard actions
 */
export function addClipboardActions(event: ShellContextMenuEvent) {
  const eventTarget = (event.composedPath()[0] ?? event.target) as HTMLElement
  const isInput = isTextInput(eventTarget)
  if (isInput) {
    const cutAction: ShellAction = {
      labelKey: asTranslationKey('Cut'),
      icon: SVG_CUT_OUTLINED,
      category: ShellActionCategoryClipboard,
      enabled: canCutText(eventTarget),
      executeFunction: () => {
        cutText(eventTarget)
      },
    }
    addContextMenuAction(event, cutAction)
  }
  const selectedText = getWindowSelectedText()
  const copyAction: ShellAction = {
    labelKey: asTranslationKey('Copy'),
    icon: SVG_COPY_OUTLINED,
    category: ShellActionCategoryClipboard,
    enabled: canCopyText(eventTarget),
    executeFunction: () => {
      copyText(selectedText)
    },
  }
  addContextMenuAction(event, copyAction)

  if (isInput) {
    const pasteAction: ShellAction = {
      labelKey: asTranslationKey('Paste'),
      icon: SVG_CLIPBOARD_OUTLINED,
      category: ShellActionCategoryClipboard,
      enabled: canPasteText(eventTarget),
      executeFunction: async () => {
        pasteText(eventTarget)
      },
    }
    addContextMenuAction(event, pasteAction)
  }
}
