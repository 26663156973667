import { LitElement, html, property, query, state } from 'lit-element'
import { getShellApiInstance, getShellAnalytics } from '../../common/shell-api-helpers'
import type { PopoverV2Component } from '@getgo/chameleon-web'
import { AvatarSize } from '../../services/avatar/models'
import { t } from '../../directives/translate'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'
import avatarContainerStyles from './avatar-container.styles.scss'
import { CLOSE_AVATAR_CONTAINER_EVENT } from './avatar-container-events'

export class GoToAvatarContainer extends LitElement {
  static readonly tagName = 'goto-avatar-container'

  static get styles() {
    return [avatarContainerStyles]
  }
  @property({ type: String }) readonly size = AvatarSize.MEDIUM
  @query('chameleon-popover-v2') private readonly chameleonPopover: PopoverV2Component | undefined
  @state() private hideAvatarTooltip = false
  private readonly givenName = getShellApiInstance().user.name.givenName ?? ''
  private readonly familyName = getShellApiInstance().user.name.lastName ?? ''
  private readonly url = getShellApiInstance().user.avatar?.isDefault
    ? ''
    : (getShellApiInstance().user.avatar?.urlMedium ?? '')

  private readonly externalUserKey = getShellApiInstance().user.key ?? ''
  private readonly toolTipLabelKey = asTranslationKey('View profile and more')

  constructor() {
    super()
    this.addEventListener(CLOSE_AVATAR_CONTAINER_EVENT, () => this.closeChameleonPopover())
  }

  /**
   * What happens when using a screen reader, or with keyboard navigation,
   * closing the popover does not remove keyboard focus on the now invisible element.
   * On a Screen reader it would be a black square with nothing there anymore.
   *
   * Because we have to cross Shadow DOM (one in trigger slot, the other in content);
   * We have to tell back what aria-controls should get back to.
   *
   * Bookmarks:
   * - https://www.w3.org/TR/2016/NOTE-UNDERSTANDING-WCAG20-20161007/consistent-behavior-receive-focus.html#consistent-behavior-receive-focus-examples-head
   * - https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/G107
   * - https://www.w3.org/TR/wai-aria-practices-1.1/examples/menu-button/menu-button-links.html
   *
   */
  private readonly focusBackToAvatar = (isOpen = false) => {
    if (isOpen) {
      this.hideAvatarTooltip = true
    } else {
      this.renderRoot.querySelector('goto-avatar')?.focus()
      this.hideAvatarTooltip = false
    }
  }

  private async handlePopoverChange(event: CustomEvent<{ isOpen: boolean }>) {
    const { isOpen = false } = event.detail

    // Mixpanel; Topbar - Avatar menu, clicked
    const status = isOpen ? 'Open' : 'Close'
    getShellAnalytics().track('GoTo > Avatar menu', { action: 'click', event: status })

    this.focusBackToAvatar(isOpen)
  }

  private closeChameleonPopover() {
    this.chameleonPopover?.close()
  }

  render() {
    return html`<goto-user-note-icon
        buttonSize="large"
        externalUserKey=${this.externalUserKey}
        canEditStatus
        tooltipZIndex="60000"
      ></goto-user-note-icon>

      <goto-avatar
        type="button"
        showPresence
        .familyName=${this.familyName}
        .givenName=${this.givenName}
        .profileImageUrl=${this.url}
        .externalUserKey=${this.externalUserKey}
        .size=${this.size}
        useChameleonAvatarV2
        variant="primary"
        ?hidePresenceTooltip="${true}"
        id="avatar-popover"
        label=${t('Open user profile menu')}
      ></goto-avatar>

      <chameleon-tooltip-v3
        delay="0"
        position="bottom-end"
        ?hidden=${this.hideAvatarTooltip}
        trigger-id="avatar-popover"
      >
        ${t(this.toolTipLabelKey)}
      </chameleon-tooltip-v3>

      <chameleon-popover-v2
        menu
        @popoverchange=${this.handlePopoverChange}
        data-test="avatar-popover-menu"
        trigger-id="avatar-popover"
        position="bottom-center"
        label=${t('User profile menu')}
      >
        <goto-avatar-popover-menu
          .familyName=${this.familyName}
          .givenName=${this.givenName}
          .profileImageUrl=${this.url}
        ></goto-avatar-popover-menu>
      </chameleon-popover-v2> `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-avatar-container': GoToAvatarContainer
  }
}
