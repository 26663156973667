import { getNavigator, getWindow } from '../../common/dom-helpers'

/**
 * Validates if an item is a text input
 * @param element element to validate
 * @returns true of element is considered as text input
 */
export const isTextInput = (element: HTMLElement | undefined): element is HTMLInputElement | HTMLTextAreaElement => {
  const validInputType = [
    'text',
    'password',
    'number',
    'email',
    'tel',
    'url',
    'search',
    'date',
    'datetime',
    'datetime-local',
    'time',
    'month',
    'week',
  ]
  const tagName = element?.tagName ?? ''
  if (tagName === 'INPUT') {
    const elementType = (element as HTMLInputElement).type
    return validInputType.includes(elementType)
  }
  return tagName === 'TEXTAREA'
}

/**
 * Validates if an element is a password input
 * @param element element to validate
 * @returns true if element is a password input, false otherwise
 */
export const isPasswordInput = (element: HTMLElement | undefined): boolean => {
  if (isTextInput(element)) {
    return (element as HTMLTextAreaElement).type === 'password'
  }
  return false
}

/**
 * Retrieves the selected text in window
 * @returns current selected text of window
 */
export const getWindowSelectedText = () => (getWindow().getSelection()?.toString() ?? '').trim()

/**
 * Validates if an element supports pasting text
 * @param element element to validate
 * @returns true if element supports pasting text
 */
export const canPasteText = (element: HTMLElement) => isTextInput(element)

/**
 * Validates if a window contains selected text to copy and if an element supports copying text
 * @param element element to validate
 * @returns true if window has text selected to copy
 */
export const canCopyText = (element: HTMLElement) => !isPasswordInput(element) && !!getWindowSelectedText()

/**
 * Validates if an element supports cutting text
 * @param element element to validate
 * @returns true if element supports cutting text
 */
export const canCutText = (element: HTMLElement) =>
  !isPasswordInput(element) && isTextInput(element) && !!getWindowSelectedText()

/**
 * Pastes text from the clipboard on a given input
 * @param input input on which to perform paste action
 * @returns new value set in input
 */
export const pasteText = async (input: HTMLInputElement | HTMLTextAreaElement) => {
  const pasteValue = await getNavigator().clipboard.readText()
  const start = input.selectionStart ?? 0
  const end = input.selectionEnd ?? start
  const currentValue = input.value
  const newValue = currentValue.substring(0, start) + pasteValue + currentValue.substring(end, currentValue.length)
  input.value = newValue
  input.selectionStart = start + pasteValue.length
  input.selectionEnd = start + pasteValue.length
  input.focus()
  return newValue
}

/**
 * Copies text to the clipboard with window's text selection
 * @param text text to copy. if not specified, selected text in window will be used.
 * @returns new value set in clipboard as text
 */
export const copyText = async (text?: string) => {
  const copyValue = text === undefined ? getWindowSelectedText() : text
  await getNavigator().clipboard.writeText(copyValue)
  return copyValue
}

/**
 * Cuts the text selection from given input and puts it in clipboard
 * @param input input on which to perform cut action
 * @returns the value set in clipboard (cut value)
 */
export const cutText = async (input: HTMLInputElement | HTMLTextAreaElement) => {
  const start = input.selectionStart ?? 0
  const end = input.selectionEnd ?? start
  const currentValue = input.value
  const cutValue = currentValue.substring(start, end)
  await getNavigator().clipboard.writeText(cutValue)
  const newValue = currentValue.substring(0, start) + currentValue.substring(end, currentValue.length)
  input.value = newValue
  return cutValue
}
