import { type ContextItem } from '../context-menu'
import type { EOMInterface } from '../eom'
import { type ShellAction } from './shell-action'

/**
 * Identifier of Context menu integration
 */
export const ContextMenuIntegrationIdentifier = '4bba1a62-3f73-41e2-ac00-c404854c1d09'

/**
 * Interface that needs to be implemented by an extension that want to interact with context menus
 */
export interface ContextMenuIntegration extends EOMInterface {
  readonly uid: typeof ContextMenuIntegrationIdentifier

  /**
   * Retrieves the actions that can be executed for given menu context
   * @param contextItems List of context items gathered for a context menu event
   * @returns list of actions to add in context menu
   */
  getContextMenuActionsForContext(contextItems: ContextItem[]): readonly ShellAction[] | undefined
}

/**
 * @private
 * Schema for type validation
 */
export const ContextMenuIntegrationSchema: ContextMenuIntegration = {
  uid: '4bba1a62-3f73-41e2-ac00-c404854c1d09',
  getContextMenuActionsForContext: () => [],
}
