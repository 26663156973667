import type { UpdateInfo } from '@getgo/container-client'
import { Container } from '@getgo/container-client'
import { html, state, query, type PropertyValues } from 'lit-element'
import { nothing } from 'lit-html'
import topbarStyles from './topbar.styles.scss'
import topbarDragHostStyles from './topbar-drag-host.styles.scss'
import topbarMacOsStyles from './topbar-mac-os.styles.scss'
import { t } from '../../directives/translate'
import { AvatarSize } from '../../services/avatar/models'
import { getPluginFunction, isContainer, isMacContainer, isWindowsContainer } from '../../services/container/helpers'
import { containerAPI } from '../../services/container'
import { getShellApiInstance, getShellAnalytics } from '../../common/shell-api-helpers'
import {
  areShellUpdatesEnabled,
  displayUpdateConfirmModal,
  getAutoUpdate,
  calculateUpdateAgeInDays,
} from '../../services/update/helpers'
import { noop } from '../../common'
import type { UpdateEvents } from '../../services/update'
import { UpdateNamespace } from '../../services/update'
import type { Listener } from '../../services/namespaces'
import { getEventBus } from '../../services/namespaces/event-bus'
import { ShellElement } from '../../common/shell-element'
import type { PresenceEvents, PresenceUpdated } from '../../services/presence'
import { PresenceServiceNamespace } from '../../services/presence'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { SVG_READER_OUTLINED, SVG_HELP_CIRCLE_OUTLINED } from '@getgo/chameleon-icons'
import { waitForElement } from '../../common/dom-helpers'
import { type Locale } from '../../services/i18n'

const calendarButtonPredicate = (node: Node) =>
  node.nodeName === 'LI' && node.firstChild?.nodeName === 'CHAMELEON-POPOVER'

const pendoLocaleWhitelist: readonly Locale[] = ['en_AU', 'en_CA', 'en_GB', 'en_IE', 'en_US']

export class GoToTopbar extends ShellElement {
  static readonly tagName = 'goto-topbar'

  @state() private updateAge: number | undefined
  @state() private hasNote = false
  @query('.container') container: HTMLSelectElement | undefined
  @query('.topbar-ul') topbarUL: HTMLUListElement | undefined

  private unsubscribeUpdateAvailable = noop

  async connectedCallback() {
    super.connectedCallback()
    if (await areShellUpdatesEnabled()) {
      this.listenToUpdateAvailableEvent()
    }

    this.addEventListener('presenceClicked', this.handlePresenceClick)

    const { presenceUpdate } = getEventBus().subscribeTo<typeof PresenceServiceNamespace, typeof PresenceEvents>(
      PresenceServiceNamespace,
    )
    const presenceUpdatedHandler: Listener<PresenceUpdated> = (payload: PresenceUpdated) => {
      const { externalUserKey, userNote } = payload
      if (getShellApiInstance().user.key === externalUserKey) {
        this.hasNote = !!userNote?.length
      }
    }

    presenceUpdate.on(presenceUpdatedHandler)

    this.unsubscribeFunctions.push(() => {
      presenceUpdate.removeListener(presenceUpdatedHandler)
    })
  }

  protected async firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties)
    if (isMacContainer()) {
      this.adaptToTrafficLightPosition()
    }
    if (this.topbarUL) {
      waitForElement(calendarButtonPredicate, this.topbarUL).then(node => {
        const secondListItemOfTopbarNav = this.topbarUL!.children[1]
        this.topbarUL!.insertBefore(node, secondListItemOfTopbarNav)
        const popover = node.getElementsByTagName('chameleon-popover')
        const iconButton = popover[0].getElementsByTagName('chameleon-icon-button')
        iconButton[0].setAttribute('size', 'large')
        node.classList.add('no-right-margin')
      })
    }
  }

  disconnectedCallback() {
    this.unsubscribeUpdateAvailable()
    this.unsubscribeUpdateAvailable = noop
    this.removeEventListener('presenceClicked', this.handlePresenceClick)
    super.disconnectedCallback()
  }

  private async adaptToTrafficLightPosition() {
    const getTrafficLightPosition = getPluginFunction('CurrentWindow', 'getTrafficLightPosition')
    if (!getTrafficLightPosition) {
      return
    }
    const trafficLightPosition = await getTrafficLightPosition()
    if (!trafficLightPosition || !this.container) {
      return
    }
    this.style.setProperty('--traffic-light-x-position', `${trafficLightPosition.x}px`)
  }

  private listenToUpdateAvailableEvent() {
    const { eventBus } = getShellApiInstance()
    const { updateAvailable } = eventBus.subscribeTo<typeof UpdateNamespace, typeof UpdateEvents>(UpdateNamespace)

    const updateAvailableHandler: Listener<UpdateInfo> = (updateInfo: UpdateInfo) => {
      this.updateAge = calculateUpdateAgeInDays(updateInfo)
    }

    updateAvailable.on(updateAvailableHandler)

    this.unsubscribeUpdateAvailable = () => {
      updateAvailable.removeListener(updateAvailableHandler)
    }

    const update = getAutoUpdate()
    if (update) {
      updateAvailableHandler(update)
    }
  }

  static get styles() {
    if (isWindowsContainer()) {
      return [topbarStyles]
    }
    if (isMacContainer()) {
      return [topbarStyles, topbarDragHostStyles, topbarMacOsStyles]
    }
    return [topbarStyles, topbarDragHostStyles]
  }

  private renderAvatarContainerListItem() {
    return html`
      <li>
        <goto-avatar-container
          class=${this.hasNote ? 'js-no-maximize has-note' : 'js-no-maximize'}
          size=${AvatarSize.MEDIUM}
        ></goto-avatar-container>
      </li>
    `
  }

  async toggleMaximize(e: MouseEvent) {
    const target = e.target as HTMLElement
    if (
      // We want the toggle to trigger on the topbar's empty space, which includes most of the container
      // div and the ul. Everything else is a button or menu item that should not trigger the (un)maximization
      !target.classList.contains('js-no-maximize') &&
      !isWindowsContainer() &&
      Container.isPluginAvailable('CurrentWindow')
    ) {
      if (containerAPI.currentWindow.handleTitleBarDoubleClick) {
        containerAPI.currentWindow.handleTitleBarDoubleClick()
      } else {
        // Remove the 'else' block when all users will have the new version @getgo/container-client@1.34.0
        const isMaximized = await containerAPI.currentWindow.isMaximized()
        if (isMaximized) {
          containerAPI.currentWindow.unmaximize()
        } else {
          containerAPI.currentWindow.maximize()
        }
      }
    }
  }

  private readonly handlePresenceClick = (event: any) => {
    getShellAnalytics().track('GoTo > Topbar - Presence', {
      from: 'Topbar',
      action: 'Selected',
      presence: event.detail.presence,
    })
  }

  private renderBrand() {
    return isContainer() ? nothing : html`<topbar-logo></topbar-logo>`
  }

  private renderUpdateButton(updateAge?: number) {
    return updateAge != undefined
      ? html`<li>
          <goto-update-chip
            .updateAge=${updateAge}
            @click=${displayUpdateConfirmModal}
            @keyup=${displayUpdateConfirmModal}
          ></goto-update-chip>
        </li>`
      : nothing
  }

  private renderAccountSwitcher() {
    return html`<goto-account-switcher></goto-account-switcher>`
  }

  private renderGlobalSearch() {
    return html`<goto-global-search></goto-global-search>`
  }

  private renderCallCardListItem() {
    return html`
      <li>
        <shell-topbar-call-card-container></shell-topbar-call-card-container>
      </li>
    `
  }
  private renderASMListItem() {
    return html`
      <li>
        <shell-asm-topbar-button></shell-asm-topbar-button>
      </li>
    `
  }
  private renderHelpIcon() {
    return html`<li class="no-right-margin">
      <chameleon-icon-button aria-label=${t('Help')} variant="primary" size="large" id="help-trigger" class="help-icon">
        <chameleon-svg>${unsafeSVG(SVG_HELP_CIRCLE_OUTLINED)}</chameleon-svg>
      </chameleon-icon-button>
      <chameleon-popover-v2 trigger-id="help-trigger" label=${t('Help menu')} id="help-popover" menu>
        <goto-topbar-help-menu></goto-topbar-help-menu>
      </chameleon-popover-v2>
    </li>`
  }

  private canSeePendoIcon() {
    const locale = getShellApiInstance().i18n.selectedLocale
    return getFeatureFlagValue('topbar-pendo-icon', false) && pendoLocaleWhitelist.includes(locale)
  }

  private renderPendoIcon() {
    return this.canSeePendoIcon()
      ? html`<li>
          <chameleon-icon-button aria-label="Resource Center" variant="primary" size="large" class="pendo-icon">
            <chameleon-svg>${unsafeSVG(SVG_READER_OUTLINED)}</chameleon-svg>
          </chameleon-icon-button>
        </li>`
      : nothing
  }

  render() {
    return html`
      <div class="container" @dblclick=${this.toggleMaximize}>
        ${this.renderBrand()} ${this.renderAccountSwitcher()} ${this.renderGlobalSearch()}
        <nav aria-label=${t('GoTo top bar')}>
          <ul class="topbar-ul">
            ${this.renderCallCardListItem()}${this.renderASMListItem()}${this.renderHelpIcon()}${this.renderPendoIcon()}${this.renderUpdateButton(
              this.updateAge,
            )}
            ${this.renderAvatarContainerListItem()}
          </ul>
        </nav>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-topbar': GoToTopbar
  }
}
