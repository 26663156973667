import type { ExtensionConfig } from '@goto/shell-common';
import { getFeatureFlag } from '../helpers/shell-helpers'
import { isLocalhost } from '../helpers/contact-center-agent-experience-helpers';

const contactCenterAgentBaseUrl = '/experiences/cc-agent';

export const extensionConfig: ExtensionConfig = {
  id: '@ucc/cc-agent/extension',
  get url() {
    // Specifying version is only used as exception for a quick rollback for instance, and not to manage deployement
    const agentVersion = getFeatureFlag('cc-agent-version', 'latest')
    return new URL(
      isLocalhost()
        ? `${contactCenterAgentBaseUrl}/extension.js` 
        : `${contactCenterAgentBaseUrl}/${agentVersion}/extension.js`,
      location.origin,
    )
  },
  prerequisites: {
    ccFlags: 'cc-agent.portal',
  },
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

export default extensionConfig
