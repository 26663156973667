/* istanbul ignore file */

/**
 * Interface describing the shape of the user schedule data returned from the backend
 */
export interface UserSchedule {
  //TODO: revisit property definitions once real data is available from the backend (https://jira.ops.expertcity.com/browse/SCORE-3447)
  /**
   * The PBX User ID of the user to which this work schedule applies
   */
  userId: string
  /**
   * The External User Key of the user
   */
  userKey: string
  /**
   * The ID of the organization of the user
   */
  orgId: string
  /**
   * The Account Key of the organization
   */
  accountKey: string
  /**
   * The source of the timezone of the user schedule
   */
  timezoneSource: TimeZoneSource
  /**
   *  The timezone of the User Schedule (from the [IANA Time Zone Database](http://www.iana.org/time-zones))
   */
  timezone: string
  /**
   * Array of recurring work periods
   */
  workPeriods: WorkPeriod[]
  /**
   * Array of one-time schedule periods that override work periods
   */
  oneTimePeriods: OneTimePeriod[]
  /**
   * Indicates whether the DnD status of the user should be updated based on the work periods
   */
  dndAutomationEnabled: boolean
  /**
   * Indicates whether the User Schedule is in use (true) or not (false)
   */
  enabled: boolean
  /**
   * Indicates whether the user is allowed to update his own work schedule.
   * If both userCanEdit and enabled are false, then the work schedule should not be visible to the user in GTC.
   */
  userCanEdit: boolean
}

/**
 * Copy of the UserSchedule interface with the oneTimePeriods property replaced with ShellOneTimePeriod
 */
export interface ShellUserSchedule extends Omit<UserSchedule, 'oneTimePeriods'> {
  oneTimePeriods: OneTimePeriodWithId[]
}

/**
 * Interface describing the shape of the user schedule data to send to the backend
 */
export interface UserScheduleUpdate {
  /**
   * The source of the timezone of the user schedule
   */
  timezoneSource: TimeZoneSource
  /**
   *  The timezone of the User Schedule (from the [IANA Time Zone Database](http://www.iana.org/time-zones))
   */
  timezone: string
  /**
   * Array of recurring work periods
   */
  workPeriods: WorkPeriod[]
  /**
   * Array of one-time schedule periods that override work periods
   */
  oneTimePeriods: OneTimePeriod[]
  /**
   * Indicates whether the DnD status of the user should be updated based on the work periods
   */
  dndAutomationEnabled: boolean
  /**
   * Indicates whether the User Schedule is in use (true) or not (false)
   */
  enabled: boolean
}

/**
 * Interface describing the shape of a recurring period during which the user is at work
 * 1) Must not overlap with another work period
 * 2) Must be at least 15 minutes long
 * 3) Maximum of 20 work periods per day
 */
export interface WorkPeriod {
  /**
   * Day of the week during which the recurring work period occurs
   */
  dayOfWeek: DayOfWeek
  /**
   *  The local start time of the work period in the ISO-8601 calendar system
   * Must be equal or larger than the end time of the previous work period
   */
  startTime: string
  /**
   *  The local end time of the work period in the ISO-8601 calendar system
   * Must be at least 15 minutes after the start time
   */
  endTime: string

  /**
   * Interface describing the shape of a non-recurring period that overrides any work period
   * 1) Must not overlap with another one-time period
   * 2) Must be at least 15 minutes long
   * 3) Must be at most 366 days long
   * 4) Maximum of 100 one-time periods per user schedule
   */
}

export interface OneTimePeriod {
  /**
   * Name given to the one-time period for display purposes
   */
  name: string
  /**
   * The local start date time (without timezone) of the one-time period in the ISO-8601 calendar system
   * Must be equal or larger than the end date of the previous one-time period
   */
  startDate: string
  /**
   * The local end date time (without timezone) of the one-time period in the ISO-8601 calendar system
   * 1) Must be at least 15 minutes after the start date
   * 2) Must be at most 366 days after the start date
   */
  endDate: string
  /**
   * Indicates whether the DnD status should be turned on (true) or off (false) during this one-time period, overriding any DnD status set by the work periods
   */
  dndEnabled: boolean
}

export interface OneTimePeriodWithId extends OneTimePeriod {
  /**
   * Unique identifier for the one time period
   */
  id: string
}

export interface ShellOneTimePeriod {
  /**
   * Unique identifier for the one time period
   */
  id: string
  /**
   * Name given to the one-time period for display purposes
   */
  name: string
  /**
   * The local start date of the one-time period in the ISO-8601 calendar system
   */
  startDay: string
  /**
   * The local end date of the one-time period in the ISO-8601 calendar system
   */
  endDay: string
  /**
   * The local start time of the one-time period in the ISO-8601 calendar system
   */
  startTime: string
  /**
   * The local end time of the one-time period in the ISO-8601 calendar system
   */
  endTime: string
  /**
   * Indicates whether the DnD status should be turned on (true) or off (false) during this one-time period, overriding any DnD status set by the work periods
   */
  dndEnabled: boolean
  /**
   * Indicates whether the one-time period is an all-day event
   */
  allDay: boolean
}

/**
 * The source of the timezone of the User Schedule
 * `USER` - Use the timezone of the Account User
 * `PBX` - Use the Organization default timezone
 * `STATIC` - Use a specific timezone
 */
export enum TimeZoneSource {
  USER = 'USER',
  PBX = 'PBX',
  STATIC = 'STATIC',
}

export const listOfDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
export type DayOfWeek = (typeof listOfDays)[number]

export enum ScheduleStatus {
  WORK_SCHEDULE = 'work_schedule',
  NO_HOURS_SET = 'no_hours_set',
}

export type ScheduleChangePayload = {
  userSchedule: ShellUserSchedule
  state: {
    isDirty: boolean
  }
}
export type ScheduleChangeListener = (data: ScheduleChangePayload) => void
