import { html, property } from 'lit-element'
import { ShellElement } from '../../common/shell-element'
import contextMenuStyles from './context-menu.styles.scss'
import { repeat } from 'lit-html/directives/repeat'
import { nothing, type TemplateResult } from 'lit-html'
import { ifDefined } from 'lit-html/directives/if-defined'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { SVG_CHEVRON_RIGHT_OUTLINED } from '@getgo/chameleon-icons'
import { type GoToShellContextMenuItem } from './models'
import { getTranslation } from '../../services/i18n/i18nUtils'

/**
 * Context menu component
 */
export class GoToShellContextMenu extends ShellElement {
  static readonly tagName = 'goto-shell-context-menu'

  /**
   * menu items to display in the context menu
   */
  @property({ type: Array })
  menuItems: readonly GoToShellContextMenuItem[] = []

  static get styles() {
    return contextMenuStyles
  }

  private readonly renderSeparator = () => html` <chameleon-menu-separator></chameleon-menu-separator>`

  private readonly renderItemIcon = (item: GoToShellContextMenuItem) =>
    item.icon ? html`<chameleon-svg slot="start">${unsafeSVG(item.icon)}</chameleon-svg>` : html`<div slot="start" style="width:1.25rem;height:1.25rem;"></div>`

  private readonly renderSubMenu = (item: GoToShellContextMenuItem, children: readonly GoToShellContextMenuItem[], renderIcon: boolean): TemplateResult => html`
    <chameleon-menu-item aria-label=${ifDefined(item.label)}>
      ${renderIcon ? this.renderItemIcon(item) : nothing} ${item.label}
      <chameleon-svg slot="end">${SVG_CHEVRON_RIGHT_OUTLINED}</chameleon-svg>
      <chameleon-menu label=${item.label ?? ''} slot="submenu">
        ${this.renderMenuItems(children)}
      </chameleon-menu>
    </chameleon-menu-item>
  `

  private readonly renderMenuItem = (item: GoToShellContextMenuItem, renderIcon: boolean): TemplateResult => {
    if (item.separator) {
      return this.renderSeparator()
    } else if (item.children?.length) {
      return this.renderSubMenu(item, item.children, renderIcon)
    }

    const handleClick = (item: GoToShellContextMenuItem) => {
      if (item.execute) {
        item.execute()
      }
      this.remove()
    }
    if (item.enabled === undefined || item.enabled) {
      return html`
      <chameleon-menu-item @menuitemclick=${() => handleClick(item)} aria-label=${ifDefined(item.label)}>
        ${renderIcon ? this.renderItemIcon(item) : nothing} ${item.label}
      </chameleon-menu-item>
    `
    }
    return html`
    <chameleon-menu-item disabled aria-label=${ifDefined(item.label)}>
      ${renderIcon ? this.renderItemIcon(item) : nothing} ${item.label}
    </chameleon-menu-item>`
  }

  private readonly renderMenuItems = (menuItems: readonly GoToShellContextMenuItem[]) => {
    const shouldRenderIcon = menuItems.some(item => !!item.icon)
    return html`
    ${repeat(
      menuItems,
      item => item,
      item => this.renderMenuItem(item, shouldRenderIcon),
    )}
    `
  }

  render() {
    return html`
    <chameleon-menu label=${getTranslation('Context menu')}>
      ${this.renderMenuItems(this.menuItems)}
    </chameleon-menu>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-shell-context-menu': GoToShellContextMenu
  }
}
