import { html, state } from 'lit-element'
import { ShellElement } from '../../../common/shell-element'
import { t } from '../../../directives/translate'
import { SVG_ADD_FILLED } from '@getgo/chameleon-icons'
import scheduleCustomHoursStyles from './schedule-custom-hours.styles.scss'
import { getDialogService } from '../../../services/dialog/public-facade'
import { getDocument } from '../../../common/dom-helpers'
import {
  CLOSE_CUSTOM_HOURS_MODAL,
  GoToScheduleCustomHoursModal,
  EDIT_OR_ADD_ONETIMEPERIOD,
  type NewOneTimePeriodEvent,
} from '../schedule-custom-hours-modal/schedule-custom-hours-modal'
import { type OneTimePeriodWithId, type ScheduleChangePayload } from '../schedule-manager/models'
import { getScheduleManager } from '../schedule-manager/schedule-manager'
import { cloneDeep } from '../../../core/helpers/clone'
import { repeat } from 'lit-html/directives/repeat'
import { getShellAnalytics } from '../../../common/shell-api-helpers'

export class GoToScheduleCustomHours extends ShellElement {
  static readonly tagName = 'goto-schedule-custom-hours'

  private customHoursModal: GoToScheduleCustomHoursModal | undefined

  @state() private isScheduleEnabled = false
  @state() private oneTimePeriods: OneTimePeriodWithId[] = []

  static get styles() {
    return scheduleCustomHoursStyles
  }

  connectedCallback(): void {
    super.connectedCallback()
    getScheduleManager().subscribe(this.handleUserScheduleChange)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    getScheduleManager().unsubscribe(this.handleUserScheduleChange)
  }

  render() {
    return html`
      <div class="custom-hours-header">
        <chameleon-typography class="small-header" variant="body-medium-strong"
          >${t('Custom hours')}</chameleon-typography
        >
        <div class="custom-hours-set">
          <chameleon-typography variant="body-small" color="type-color-secondary"
            >${t(
              'Add holidays, quiet hours, and other exceptions to your schedule availability.',
            )}</chameleon-typography
          ><chameleon-button
            class="add-custom-hours"
            variant="secondary"
            size="medium"
            @click=${() => {
              getDialogService().open(this.createCustomHoursModal())
            }}
            ?disabled=${!this.isScheduleEnabled}
            ><chameleon-svg slot="start">${SVG_ADD_FILLED}</chameleon-svg>${t('Add custom hours')}</chameleon-button
          >
        </div>
      </div>
      <div class="custom-hours-schedule">
        ${repeat(
          this.oneTimePeriods || [],
          period => period.id,
          period => this.renderCustomHoursCard(period),
        )}
      </div>
    `
  }

  private handleUserScheduleChange = (payload: ScheduleChangePayload) => {
    this.oneTimePeriods = payload.userSchedule.oneTimePeriods
    this.isScheduleEnabled = payload.userSchedule.enabled
  }

  private handleDeleteOneTimePeriod(oneTimePeriod: OneTimePeriodWithId) {
    getShellAnalytics().track('GoTo > Schedule settings - Custom hours', {
      action: 'delete',
      oneTimePeriod: oneTimePeriod,
    })
    this.oneTimePeriods = this.oneTimePeriods.filter(period => period.id !== oneTimePeriod.id)
    getScheduleManager().updateOneTimePeriods(this.oneTimePeriods)
  }

  private handleEditOneTimePeriod(oneTimePeriod: OneTimePeriodWithId) {
    getDialogService().open(this.createCustomHoursModal(oneTimePeriod))
  }

  private updateCustomHoursSchedule(oneTimePeriod: OneTimePeriodWithId) {
    const existingObjectIndex = this.oneTimePeriods.findIndex(period => period.id === oneTimePeriod.id)
    if (existingObjectIndex !== -1) {
      this.oneTimePeriods = [
        ...this.oneTimePeriods.slice(0, existingObjectIndex),
        oneTimePeriod,
        ...this.oneTimePeriods.slice(existingObjectIndex + 1),
      ]
    } else {
      this.oneTimePeriods = [...this.oneTimePeriods, oneTimePeriod]
    }

    getScheduleManager().updateOneTimePeriods(this.oneTimePeriods)
  }

  private createCustomHoursModal(oneTimePeriod?: OneTimePeriodWithId) {
    this.customHoursModal = getDocument().createElement(GoToScheduleCustomHoursModal.tagName)
    if (oneTimePeriod) {
      this.customHoursModal.oneTimePeriod = cloneDeep(oneTimePeriod)
    }
    this.customHoursModal.addEventListener(EDIT_OR_ADD_ONETIMEPERIOD, this.handleAddOrEditOneTimePeriod)
    this.customHoursModal.addEventListener(CLOSE_CUSTOM_HOURS_MODAL, this.handleCloseCustomHoursModal)
    return this.customHoursModal
  }

  private readonly handleAddOrEditOneTimePeriod = (event: CustomEvent<NewOneTimePeriodEvent>) => {
    this.updateCustomHoursSchedule(event.detail.oneTimePeriod)
    this.closeCustomHoursModal()
  }

  private readonly handleCloseCustomHoursModal = () => {
    this.closeCustomHoursModal()
  }

  private closeCustomHoursModal() {
    // Removing the listeners when closing an instance of the modal. This is to avoid memory leaks as the modal can be opened and closed multiple times
    this.customHoursModal?.removeEventListener(EDIT_OR_ADD_ONETIMEPERIOD, this.handleAddOrEditOneTimePeriod)
    this.customHoursModal?.removeEventListener(CLOSE_CUSTOM_HOURS_MODAL, this.handleCloseCustomHoursModal)
    this.customHoursModal = undefined
    getDialogService().close()
  }

  private renderCustomHoursCard = (oneTimePeriod: OneTimePeriodWithId) =>
    html`<goto-schedule-custom-hours-card
      .oneTimePeriod=${oneTimePeriod}
      ?isScheduleEnabled=${this.isScheduleEnabled}
      @edit=${() => this.handleEditOneTimePeriod(oneTimePeriod)}
      @delete=${() => this.handleDeleteOneTimePeriod(oneTimePeriod)}
    ></goto-schedule-custom-hours-card>`
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-custom-hours': GoToScheduleCustomHours
  }
}
