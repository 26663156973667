import { getLocationHostname } from './common/dom-helpers'

export enum Environment {
  // Note: The naming below reflects the differences in the 2 environments the company currently has
  // In 2021, these will be unified to ED (by renaming dev in all environments). We will have to clean this up then
  ED = 'dev',
  RC = 'rc',
  STAGING = 'staging', // Note: STAGING and STAGE are considered to be equivalent
  NIGHTLY = 'nightly',
  PROD = 'prod',
}
interface EnvironmentBase {
  /**
   * Calendar endpoint
   */
  calendarServiceBaseUrl: string
  /**
   * A getter for the GTC BFF endpoint
   * ref: https://my.goto.cloud/catalog/default/api/gtc-bff/definition
   */
  gtcBffServiceBaseUrl(): string

  /**
   * A getter for the Shell BFF endpoint
   */
  getShellBffServiceBaseUrl(): string
  /**
   * For more details visit:
   *  - https://confluence.ops.expertcity.com/display/SOA/Auth+Services+Home
   *  - https://confluence.ops.expertcity.com/display/SOA/Authentication+Services+Domain+Mapping
   */
  readonly authRedirectUrl: string
  readonly authRedirectUrlContainerSignOut: string
  readonly logoutRedirectUrl: string
  readonly supportUrl: string
  readonly calendarConnectedUrl: string

  /**
   * Required by the UserReport script https://www.userreport.com/
   */
  readonly userReportURL: string
  readonly userReportUUID: string

  readonly helpUrl: string
  readonly tutorialsAndVideosUrl: string
  readonly communityForumUrl: string
  readonly gotoConnectHelpUrl: string
  readonly gotoMeetingHelpUrl: string
  readonly gotoTrainingHelpUrl: string

  readonly windowsDesktopUrl: string
  readonly macDesktopUrl: string
}

export interface EnvironmentConfig extends EnvironmentBase {
  readonly environment: Environment
  /**
   * For more details visit:
   *  - https://confluence.ops.expertcity.com/display/SOA/Auth+Services+Home
   *  - https://confluence.ops.expertcity.com/display/SOA/Authentication+Services+Domain+Mapping
   */
  readonly authClientId: string

  readonly apiGlobal: string
  /**
   * For more details visit:
   *  - https://confluence.ops.expertcity.com/display/SOA/Auth+Services+Home
   *  - https://confluence.ops.expertcity.com/display/SOA/Authentication+Services+Domain+Mapping
   */
  readonly authUrl: string
  /**
   * For more details visit: https://confluence.ops.expertcity.com/display/SOA/SCIM+Identity+Service
   */
  readonly iamBaseUrl: string
  /**
   * For more details related to Identity visit: https://confluence.ops.expertcity.com/display/SOA/SCIM+Identity+Service#SCIMIdentityService-Avatars
   * For more details related to External Admin visit: https://confluence.ops.expertcity.com/display/SOA/External+Admin+REST+API
   */
  readonly avatarBaseUrl: string
  /**
   * For more details visit: https://developers.devops.jive.com
   */
  readonly jiveApiBaseUrl: string
  /**
   * Launch darkly client id see : https://app.launchdarkly.com/settings/projects/go-to-connect-web/environments
   */
  readonly launchDarklyClientId: string
  /**
   * Unleash Access Token see : https://api.jive.com/feature-flags/v1/
   */
  readonly unleashAccessToken: string

  /**
   * server for remote logging
   */
  readonly loggingServerUrl: string
  readonly notificationChannelUrl: string
  readonly profileUrl: string
  /**
   * https://developer.mixpanel.com/docs/javascript-quickstart
   */
  readonly mixpanelToken: string
  /**
   * For more details visit:
   *  - https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=SOA&title=CaPS+Deployed+Environments
   */
  readonly caPSBaseUrl: string
  readonly myAccountPageUrl: string

  readonly presenceServiceUrl: string
  /**
   * GotoResolve
   */
  readonly gotoResolveUrl: string
  /**
   * Global Search Base Url
   */
  readonly searchBaseUrl?: string
  /**
   * Pendo script src
   */
  readonly pendoScriptSrc?: string
  /**
   * External Interface ports
   */
  readonly externalInterfacePorts?: number[]
  /**
   * The appId from the MS Teams app manifest (the `id` from the manifest.json)
   */
  readonly msTeamsManifestAppId?: string
  /**
   * The entityId of the Home tab within the MS Teams app (the `entityId` from the `staticTabs` array from the manifest.json)
   */
  readonly msTeamsManifestEntityId?: string
}

const PROFILE_URL_PATH = `personal-info`

const GTC_BFF_SERVICE_PATH = '/v5/gtc-bff/v2'

// Required by the UserReport script https://www.userreport.com/
export const userReportUUID = '8300b634-3d8c-4d2f-8293-444677d35357'
export const userReportURL = 'https://cdn.userreport.com/userreport.js'

export const BASEURL_AUTH = Object.freeze({
  redirect: '/g2m-oauth',
})
export const landingURL = '/landing'
export const calendarConnectedBase = '/calendar-connected'

export const TRIAL_PURCHASE_URL =
  'https://www.goto.com/pricing/meeting?utm_campaign=gtm_trial_countdown&utm_source=goto-app&utm_medium=ipd&utm_content=buy-now&utm_term=global_trial'

export const TERMS_OF_SERVICE_EN_URL = 'https://www.goto.com/en/company/legal/terms-and-conditions'
export const TERMS_OF_SERVICE_ES_URL = 'https://www.goto.com/es/company/legal/terms-and-conditions'
export const TERMS_OF_SERVICE_DE_URL = 'https://www.goto.com/de/company/legal/terms-and-conditions'
export const TERMS_OF_SERVICE_FR_URL = 'https://www.goto.com/fr/company/legal/terms-and-conditions'
export const TERMS_OF_SERVICE_PT_URL = 'https://www.goto.com/pt/company/legal/terms-and-conditions'

const LAUNCH_DARKLY_CONFIG: Record<Environment, string> = {
  [Environment.ED]: '5d7a8e33500b5907acbc7841',
  [Environment.NIGHTLY]: '5ddd25fb54128a0824cc7781',
  [Environment.STAGING]: '5d8a76c411036907a283bfb0',
  [Environment.RC]: '607f8db6bcf4820be9b1464f',
  [Environment.PROD]: '5d7a8de3b6722a07a58219ec',
}

const UNLEASH_CONFIG: Record<Environment, string> = {
  [Environment.ED]: 'GoToApp:ED.294379d0ffef202b408bc8ace2184d44bfbdc980d524bbc1d5234535',
  [Environment.NIGHTLY]: 'GoToApp:Nightly.73e08dcaba411ee6eccde30c2d2f0f4e5d299810a873e469195f2840',
  [Environment.RC]: 'GoToApp:RC.4c1b2413d434409eeef423fd46cb9f9a1810435587e3c0a910559b1f',
  [Environment.STAGING]: 'GoToApp:Stage.9bed2b009ae272797d036759293a9548154e9556bc3ff024003cafe6',
  [Environment.PROD]: 'GoToApp:Production.2fbaf303e2c84bd5e8a3877dc9d3a3c9480bf76ddfdeae36233704cf',
}

const MIXPANEL_CONFIG: Record<Environment, string> = {
  [Environment.ED]: '9ecffe57d3b75b7081a3f5d970a93259',
  [Environment.NIGHTLY]: '9ecffe57d3b75b7081a3f5d970a93259',
  [Environment.STAGING]: '9ecffe57d3b75b7081a3f5d970a93259',
  [Environment.RC]: '9ecffe57d3b75b7081a3f5d970a93259',
  [Environment.PROD]: 'c5934d98a88971c01e14163bfa6b1ee2',
}

const environmentBase: EnvironmentConfig = {
  gtcBffServiceBaseUrl() {
    return `${this.jiveApiBaseUrl}${GTC_BFF_SERVICE_PATH}`
  },
  getShellBffServiceBaseUrl() {
    return `${this.jiveApiBaseUrl}/shell-bff/v1`
  },
  authRedirectUrl: `${window.location.origin}${BASEURL_AUTH.redirect}`,
  authRedirectUrlContainerSignOut:
    'https://authentication.logmeininc.com/oauth/logout?client_id=a7717fe1-75ce-4f82-b477-2f6dabe3eed9&redirect_uri',
  calendarConnectedUrl: `${window.location.origin}${calendarConnectedBase}`,
  logoutRedirectUrl: `${window.location.origin}/`,
  supportUrl: 'https://support.goto.com/connect/help/how-do-i-contact-gotoconnect-customer-support',
  userReportURL: 'http://cdn.userreport.com/userreport.js',
  userReportUUID: '8300b634-3d8c-4d2f-8293-444677d35357',
  helpUrl: 'https://support.goto.com/products',
  tutorialsAndVideosUrl: 'https://link.goto.com/EmbUserInProdUx',
  communityForumUrl: 'https://community.logmein.com/category/GoToConnect',
  windowsDesktopUrl: 'https://goto-desktop.goto.com/GoTo.exe',
  macDesktopUrl: 'https://goto-desktop.goto.com/GoTo.dmg',
  myAccountPageUrl: 'http://myaccount.logmeininc.com/',
  gotoResolveUrl: 'https://ticketing-app.console.gotoresolve.com/api/v1/goto_connect',
  pendoScriptSrc: 'pendo.js',
} as EnvironmentConfig

export const edEnvironment: EnvironmentConfig = {
  ...(environmentBase as EnvironmentBase),
  environment: Environment.ED,
  apiGlobal: 'https://apiglobaled1.g2m.test.expertcity.com',
  authClientId: '5add16df-e35f-4529-b84a-5f428fbb07b4',
  authUrl: 'https://authenticationed1.lmiinc.test.expertcity.com',
  authRedirectUrlContainerSignOut:
    'https://authenticationed1.lmiinc.test.expertcity.com/oauth/logout?client_id=5add16df-e35f-4529-b84a-5f428fbb07b4&redirect_uri',
  iamBaseUrl: 'https://iamed1.serversdev.getgo.com',
  avatarBaseUrl: 'https://avatarsed1.serversdev.getgo.com',
  jiveApiBaseUrl: 'https://api.dev.jive.com',
  launchDarklyClientId: LAUNCH_DARKLY_CONFIG[Environment.ED],
  unleashAccessToken: UNLEASH_CONFIG[Environment.ED],
  calendarServiceBaseUrl: 'https://calendar-integration.services-ed.goto.com',
  logoutRedirectUrl: `${window.location.origin}/`,
  loggingServerUrl: 'https://logginged1.getgo.test.expertcity.com',
  notificationChannelUrl: 'https://webrtc.dev.jive.com/notification-channel/v1',
  profileUrl: `https://profileed.serversdev.getgo.com/${PROFILE_URL_PATH}`,
  mixpanelToken: MIXPANEL_CONFIG[Environment.ED],
  caPSBaseUrl: 'https://xmpp-ed.serversdev.getgo.com',
  myAccountPageUrl: 'http://myaccount.logmeininc.com/',
  presenceServiceUrl: 'https://api.dev.jive.com/presence/v1',
  gotoResolveUrl: 'https://ticketing-app.dev01-console.gotoresolve.com/api/v1/goto_connect',
  searchBaseUrl: 'http://localhost:3000',
  pendoScriptSrc: 'pendo-staging.js',
  externalInterfacePorts: [2001, 2002, 2003],
  msTeamsManifestAppId: 'fdb1dc12-90b2-4d37-9add-860f2f2e5a90',
  msTeamsManifestEntityId: 'HomeTabED',
}

export const rcEnvironment: EnvironmentConfig = {
  ...(environmentBase as EnvironmentBase),
  getShellBffServiceBaseUrl() {
    return `${this.jiveApiBaseUrl}/shell-bff-rc/v1`
  },
  environment: Environment.RC,
  apiGlobal: 'https://apiglobalrc1.g2m.test.expertcity.com',
  authClientId: '43c6cbec-5ca5-4849-85d4-9872fd98eb67',
  authUrl: 'https://authenticationrc1.lmiinc.test.expertcity.com',
  authRedirectUrlContainerSignOut:
    'https://authenticationrc1.lmiinc.test.expertcity.com/oauth/logout?client_id=43c6cbec-5ca5-4849-85d4-9872fd98eb67&redirect_uri',
  iamBaseUrl: 'https://iamrc1.serversdev.getgo.com',
  avatarBaseUrl: 'https://avatarsrc1.serversdev.getgo.com',
  jiveApiBaseUrl: 'https://api.dev.jive.com',
  launchDarklyClientId: LAUNCH_DARKLY_CONFIG[Environment.RC],
  unleashAccessToken: UNLEASH_CONFIG[Environment.RC],
  calendarServiceBaseUrl: 'https://calendar-integration.services-rc.goto.com',
  logoutRedirectUrl: `${window.location.origin}`,
  loggingServerUrl: 'https://loggingrc1.getgo.test.expertcity.com',
  notificationChannelUrl: 'https://webrtc.dev.jive.com/notification-channel/v1',
  profileUrl: `https://profilerc.serversdev.getgo.com/${PROFILE_URL_PATH}`,
  mixpanelToken: MIXPANEL_CONFIG[Environment.RC],
  caPSBaseUrl: 'https://xmpp-rc.serversdev.getgo.com',
  myAccountPageUrl: 'http://myaccount.logmeininc.com/',
  presenceServiceUrl: 'https://api.dev.jive.com/presence/v1',
  gotoResolveUrl: 'https://ticketing-app.dev01-console.gotoresolve.com/api/v1/goto_connect',
  pendoScriptSrc: 'pendo-staging.js',
  externalInterfacePorts: [3001, 3002, 3003],
  msTeamsManifestAppId: 'fdb1dc12-90b2-4d37-9add-860f2f2e5a90',
  msTeamsManifestEntityId: 'HomeTabRC',
}

export const stagingEnvironment: EnvironmentConfig = {
  ...(environmentBase as EnvironmentBase),
  environment: Environment.STAGING,
  apiGlobal: 'https://apiglobalstage.gotomeeting.com',
  authClientId: '9463723b-68c6-4191-962a-37bb84a60b44',
  authUrl: 'https://authenticationstage.logmeininc.com',
  authRedirectUrlContainerSignOut:
    'https://authenticationstage.logmeininc.com/oauth/logout?client_id=9463723b-68c6-4191-962a-37bb84a60b44&redirect_uri',
  iamBaseUrl: 'https://iamstage.servers.getgo.com',
  avatarBaseUrl: 'https://avatarsstage.servers.getgo.com',
  jiveApiBaseUrl: 'https://api.staging.jive.com',
  launchDarklyClientId: LAUNCH_DARKLY_CONFIG[Environment.STAGING],
  unleashAccessToken: UNLEASH_CONFIG[Environment.STAGING],
  calendarServiceBaseUrl: 'https://calendar-integration.services-stage.goto.com',
  logoutRedirectUrl: `${window.location.origin}`,
  loggingServerUrl: 'https://loggingstage.getgo.com',
  notificationChannelUrl: 'https://webrtc.staging.jive.com/notification-channel/v1',
  profileUrl: `https://profilestage.servers.getgo.com/${PROFILE_URL_PATH}`,
  mixpanelToken: MIXPANEL_CONFIG[Environment.STAGING],
  caPSBaseUrl: 'https://xmpp-stage.servers.getgo.com/',
  myAccountPageUrl: 'http://myaccount.logmeininc.com/',
  presenceServiceUrl: 'https://api.staging.jive.com/presence/v1',
  gotoResolveUrl: 'https://ticketing-app.stage-console.gotoresolve.com/api/v1/goto_connect',
  pendoScriptSrc: 'pendo-staging.js',
  externalInterfacePorts: [4001, 4002, 4003],
  msTeamsManifestAppId: '4b956fb6-b72c-48a6-b3e2-326693cc2881',
  msTeamsManifestEntityId: 'HomeTab',
}

export const prodEnvironment: EnvironmentConfig = {
  ...(environmentBase as EnvironmentBase),
  environment: Environment.PROD,
  apiGlobal: 'https://apiglobal.gotomeeting.com',
  authClientId: 'a7717fe1-75ce-4f82-b477-2f6dabe3eed9',
  authUrl: 'https://authentication.logmeininc.com',
  authRedirectUrlContainerSignOut:
    'https://authentication.logmeininc.com/oauth/logout?client_id=a7717fe1-75ce-4f82-b477-2f6dabe3eed9&redirect_uri',
  iamBaseUrl: 'https://iam.servers.getgo.com',
  avatarBaseUrl: 'https://avatars.servers.getgo.com',
  jiveApiBaseUrl: 'https://api.jive.com',
  launchDarklyClientId: LAUNCH_DARKLY_CONFIG[Environment.PROD],
  unleashAccessToken: UNLEASH_CONFIG[Environment.PROD],
  calendarServiceBaseUrl: 'https://calendar-integration.services.goto.com',
  logoutRedirectUrl: `${window.location.origin}`,
  loggingServerUrl: 'https://logging.getgo.com',
  notificationChannelUrl: 'https://webrtc.jive.com/notification-channel/v1',
  profileUrl: `https://profile.logmeininc.com/${PROFILE_URL_PATH}`,
  mixpanelToken: MIXPANEL_CONFIG[Environment.PROD],
  caPSBaseUrl: 'https://xmpp.servers.getgo.com',
  myAccountPageUrl: 'http://myaccount.logmeininc.com/',
  presenceServiceUrl: 'https://api.jive.com/presence/v1', // keep on api.jive until any other notice for production
  gotoResolveUrl: 'https://ticketing-app.console.gotoresolve.com/api/v1/goto_connect',
  pendoScriptSrc: 'pendo.js',
  externalInterfacePorts: [11801, 16756, 22229],
  msTeamsManifestAppId: '4b956fb6-b72c-48a6-b3e2-326693cc2881',
  msTeamsManifestEntityId: 'HomeTab',
}

const nightlyEnvironment: EnvironmentConfig = {
  ...prodEnvironment,
  environment: Environment.PROD, // the nightly config must mirror the PROD config except for minor changes like the launchDarkly flag
  launchDarklyClientId: LAUNCH_DARKLY_CONFIG[Environment.NIGHTLY],
  unleashAccessToken: UNLEASH_CONFIG[Environment.NIGHTLY],
  mixpanelToken: MIXPANEL_CONFIG[Environment.NIGHTLY],
  externalInterfacePorts: [5001, 5002, 5003],
  msTeamsManifestAppId: 'd96ad4b9-5b46-4672-b27e-b0987fc4df9c',
  msTeamsManifestEntityId: 'HomeTabNightly',
}

const ShellConfigs: { readonly [env in Environment]: EnvironmentConfig } = {
  dev: edEnvironment,
  staging: stagingEnvironment,
  rc: rcEnvironment,
  nightly: nightlyEnvironment,
  prod: prodEnvironment,
}

export const getCurrentEnvironment = (): Environment =>
  getLocationHostname().includes(Environment.NIGHTLY)
    ? Environment.NIGHTLY
    : globalThis.shellEnvironmentConfig?.environment

export const isProdEnvironment = () => getCurrentEnvironment() === Environment.PROD

export const environment = () => ShellConfigs[getCurrentEnvironment()]

export enum LocalStorageKeys {
  goto_launch_darkly = 'goto-launch-darkly',
  goto_launch_darkly_override = 'goto-launch-darkly-override',
  gotoAppFeatureFlags = 'goto-app-feature-flags',
  gotoAppFeatureFlagsOverride = 'goto-app-feature-flags-override',
  launch_darkly_uuid = 'launch-darkly-uuid',
  gotoNonce = 'goto-nonce',
  authLocalStorageKey = 'goto',
  refreshTokenLocalStorageKey = 'shell-fgqp489hadgnosugq49p7gwhe8agsdg',
  tokenExpirationDate = 'expiry',
  notificationSettings = 'notificationSettings',
  jivewebNotificationSettings = 'jive-web/settings',
  gotoExperiencesOverride = 'goto-experiences-override',
  gotoExtensionsOverride = 'goto-extensions-override',
  hasClosedDownloadDesktopAppBanner = 'promote-desktop-id',
  hasAcceptedTermsOfServiceInGTC = 'ngStorage-termsOfServiceAccepted',
  navigationLinkBadges = 'navigation-link-badges',
  gotoCurrentRoute = 'goto-current-route',
  gotoCurrentExtensionId = 'goto-current-extension-id',
  chameleonTheme = 'chameleon-theme',
  userPreferences = 'userPreferences',
  userCache = 'user-cache',
  teamsUsePlugin = 'teams-use-plugin',
}

export const SessionStorageKeys = {
  inflightRequest: 'inflightRequest',
  pathBeforeNavigationToSearchPage: 'path-before-navigation-to-search-page',
} as const

export const deployedURLs = {
  dev: 'app.ed.gtc.goto.com',
  rc: 'app.nightly.goto.com',
  nightly: 'app.nightly.goto.com',
  prod: 'app.goto.com',
  extended_prod: 'extended.goto.com',
  candidate_prod: 'candidate.goto.com',
}

/**
 * chameleon base url used to fetch the theme stylesheet with a specific version
 **/
export const chameleonThemeStylesheetBaseUrl = 'https://chameleon.goto.com/themes'
