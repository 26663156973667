import { type DateTimeFormatOptions } from '../../services/i18n'
import { type WorkPeriod, type DayOfWeek } from './schedule-manager/models'
import { asTranslationKey } from '../../common/translate-helpers/i18n-utils'

export const defaultWorkScheduleTime = {
  start: '09:00:00',
  end: '17:00:00',
} as const

export const defaultWorkScheduleDays: ReadonlyArray<DayOfWeek> = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
]

export const timeFormatOptions: Readonly<DateTimeFormatOptions> = {
  hour: 'numeric',
  minute: 'numeric',
}

export const dateTimeFormatOptions: Readonly<DateTimeFormatOptions> = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const daysOfWeekTranslationKeys: Readonly<{ [key in DayOfWeek]: string }> = {
  MONDAY: asTranslationKey('Monday'),
  TUESDAY: asTranslationKey('Tuesday'),
  WEDNESDAY: asTranslationKey('Wednesday'),
  THURSDAY: asTranslationKey('Thursday'),
  FRIDAY: asTranslationKey('Friday'),
  SATURDAY: asTranslationKey('Saturday'),
  SUNDAY: asTranslationKey('Sunday'),
}

export const createDefaultWorkPeriods = (): WorkPeriod[] =>
  defaultWorkScheduleDays.map(dayOfWeek => ({
    dayOfWeek,
    startTime: defaultWorkScheduleTime.start,
    endTime: defaultWorkScheduleTime.end,
  }))

export interface EndTimeOptions {
  value: string
  hidden: boolean
}
