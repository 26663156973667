/* istanbul ignore file */
import Shell from './core/shell'
import './packages/shell-controller/shell-controller'
import './sass/index.scss'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { registerServiceWorkerOnWindowLoad } from './service-workers/shell-sw-registration'

registerServiceWorkerOnWindowLoad()

export default new Shell()
