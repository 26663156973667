import { getLocationPathname } from './../../common/dom-helpers'
import { html } from 'lit-element'
import { nothing } from 'lit-html'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { navigateToUrl } from '../../common/helpers'
import { getResolvedIcon } from '../../common/icon-helpers'
import { t } from '../../directives/translate/translate'
import type { IconDefinition } from '@goto/shell-common'
import { getShellApiInstance, getShellAnalytics } from '../../common/shell-api-helpers'
import type { InternalSpecificSettingLink, InternalGeneralSettingLink } from './settings-models'
import { resolveTranslatable } from '../../common/translate-helpers/translatable'
import type { Translatable } from '../../common/translate-helpers/types'
import { type UserProfileEntitlement } from '../../'
import { getBadgeNumbersAPI } from '../../services/badge-numbers'
import { getEntitlementsFromUserProfile } from '../../services/identity/userProfile'

export const isVisible = async (link: InternalGeneralSettingLink | InternalSpecificSettingLink) => {
  let isVisible = link._isVisible

  if (isVisible === undefined) {
    const user = getShellApiInstance().user
    isVisible = await link.isVisible?.(
      getEntitlementsFromUserProfile(getShellApiInstance().user) as UserProfileEntitlement[],
      user.emails,
    )
    link._isVisible = isVisible
  }

  return isVisible
}

export const SETTINGS_IS_DIRTY_ID = 'settings-is-dirty'

export const renderMenuItem = (link: InternalGeneralSettingLink, accordionName?: Translatable | undefined) => {
  const resolvedTranslatableLinkName = link.name ? resolveTranslatable(link.name, { raw: true }) : link.name

  const isDirty =
    getBadgeNumbersAPI().getBadgeNumberValue({
      id: SETTINGS_IS_DIRTY_ID,
      settingsName: resolvedTranslatableLinkName,
    }) > 0

  return html` <chameleon-navigation-drawer-item
    data-test=${resolvedTranslatableLinkName}
    title=${t(link.name)}
    ?selected=${getLocationPathname() === link.href}
    @click=${() => {
      const linkName = accordionName ? resolveTranslatable(accordionName, { raw: true }) : accordionName
      const target = resolveTranslatable(link.name, { raw: true })
      getShellAnalytics().track(`GoTo > Settings`, {
        action: 'Click',
        linkName,
        target,
      })

      navigateToUrl(link.href)
    }}
  >
    ${link.icon
      ? html`<chameleon-svg slot="start">${renderIcon(link.icon, getLocationPathname() === link.href)}</chameleon-svg>`
      : nothing}
    ${t(link.name)}
    ${isDirty
      ? html`<chameleon-text-badge slot="end" variant="warning" border="fill">${t('Not saved')}</chameleon-text-badge>`
      : nothing}
  </chameleon-navigation-drawer-item>`
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const renderIcon = (icon: Function | IconDefinition, active: boolean) => {
  const iconValue = typeof icon === 'function' ? icon(active) : getResolvedIcon(icon, { active })
  return unsafeSVG(iconValue)
}
