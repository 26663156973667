import { type GoToShellContextMenuItem } from './models'
import { executeCommand } from '../../services/namespaces'
import { type DisplayableShellAction } from '../../extensions/models'

const ActionSeparatorLabel = '-'
const createSeparator = () => ({ separator: true })

/**
 * Removes all properties set to undefined
 * @param obj Object to clean
 * @returns the clean object
 */
const cleanupObject = (obj: { [key: string]: unknown }) => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])
  return obj
}

/**
 * Sort actions per category
 * @param actions Actions to sort
 * @returns sorted actions
 */
const sortByCategories = (actions: readonly DisplayableShellAction[]): readonly DisplayableShellAction[] =>
  [...actions].sort((action1, action2) => (action1.category ?? '').localeCompare((action2.category ?? '')))

/**
 * Convert actions list into a list of context menu items. It also sorts item per category and adds
 * separator between different categories if there's none
 * @param actions Actions to convert
 * @returns Menu Items created from actions
 */
export const actionsToMenuItems = (actions: readonly DisplayableShellAction[]): GoToShellContextMenuItem[] => {
  const result: GoToShellContextMenuItem[] = []
  const sortedItems = sortByCategories(actions)
  sortedItems.forEach((currentAction, index) => {
    const lastAction = sortedItems[index - 1]
    const isSeparator = currentAction.label === ActionSeparatorLabel
    if ((isSeparator || lastAction?.category !== currentAction.category)) {
      result.push(createSeparator())
    }
    if (!isSeparator) {
      result.push(actionToMenuItem(currentAction))
    }

  })

  return removeUselessSeparators(result)
}

/**
 * Removes all useless separators (first/last item, duplicates)
 * @param menuItems list of menu items
 * @returns list of menu items without 
 */
const removeUselessSeparators = (menuItems: GoToShellContextMenuItem[]) =>
  menuItems.filter((menuItem, index) =>
    !menuItem.separator || (index > 0 && index < menuItems.length - 1 && (!menuItems[index - 1]?.separator))
  )

/**
 * Converts an action to a menu item
 */
const actionToMenuItem = (action: DisplayableShellAction): GoToShellContextMenuItem => cleanupObject({
  label: action.label,
  enabled: (action.enabled === undefined || action.enabled),
  icon: action.icon,
  execute: () => {
    if (action.command) {
      executeCommand(action.command)
    } else if (action.executeFunction) {
      action.executeFunction()
    }
  },
  children: action.children?.length ? actionsToMenuItems(action.children) : undefined
})
