import { html, property, type PropertyValues, state } from 'lit-element'
import { t } from '../../../directives/translate'
import { ShellElement } from '../../../common/shell-element'
import scheduleCustomHoursModalStyles from './schedule-custom-hours-modal.styles.scss'
import {
  type ScheduleChangePayload,
  type OneTimePeriodWithId,
  type ShellOneTimePeriod,
} from '../schedule-manager/models'
import {
  formatISOTime,
  getListOfTimeForOneDayWithInterval,
  getNextValidEndTime,
  getValidEndTimeOptions,
  initialTimeOfTheDay,
  lastTimeOfTheDay,
  getCurrentLocalDate,
  getUserLocaleInDatePickerFormat,
  getMaximumCalendarEndDate,
  getMinimumCalendarEndDate,
  timeIntervalMinutes,
  getAllEndTimeOptions,
} from '../schedule-settings-utils'
import { type ChangeEvent } from 'react'
import { type CheckboxComponent } from '@getgo/chameleon-web'
import { nothing } from 'lit-html'
import {
  hasAllRequiredCustomHoursFieldsFilled,
  convertOneTimePeriodWithIdToShellOneTimePeriod,
  convertShellOneTimePeriodToOneTimePeriodWithId,
  oneTimePeriodHasOverlap,
  createShellOneTimePeriod,
} from '../helpers'
import { getUserTimezone } from '../../../common/user-helpers'
import { getScheduleManager } from '../schedule-manager/schedule-manager'
import { compareValues } from '../../../core/helpers/compare'

export interface NewOneTimePeriodEvent {
  readonly oneTimePeriod: OneTimePeriodWithId
}

export const EDIT_OR_ADD_ONETIMEPERIOD = 'edit-or-add-onetimeperiod'
export const CLOSE_CUSTOM_HOURS_MODAL = 'close-custom-hours-modal'

export class GoToScheduleCustomHoursModal extends ShellElement {
  static readonly tagName = 'goto-schedule-custom-hours-modal'
  @property({ type: Object }) set oneTimePeriod(oneTimePeriod: OneTimePeriodWithId) {
    this.originalShellOneTimePeriod = convertOneTimePeriodWithIdToShellOneTimePeriod(oneTimePeriod)
    this.shellOneTimePeriod = convertOneTimePeriodWithIdToShellOneTimePeriod(oneTimePeriod)
  }
  @state() private shellOneTimePeriod: ShellOneTimePeriod = createShellOneTimePeriod()
  @state() private endTimeOptions = getAllEndTimeOptions()
  @state() private isFormValid = false
  @state() private hasOverlap = false
  private startTimeOptions = getListOfTimeForOneDayWithInterval(timeIntervalMinutes)
  private originalShellOneTimePeriod: ShellOneTimePeriod | undefined = undefined
  private shellOneTimePeriods: ShellOneTimePeriod[] = []

  static get styles() {
    return scheduleCustomHoursModalStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.subscribeToScheduleChanges()
  }

  protected firstUpdated(changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties)
    // if a start time exists when the component is first rendered, hide the invalid end time options
    if (this.shellOneTimePeriod.startTime) {
      this.endTimeOptions = getValidEndTimeOptions(this.shellOneTimePeriod.startTime, this.endTimeOptions)
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties)
    if (changedProperties.has('shellOneTimePeriod')) {
      this.validateForm()
    }
  }

  private subscribeToScheduleChanges() {
    getScheduleManager().subscribe(this.handleUserScheduleChange)
    this.unsubscribeFunctions.push(() => getScheduleManager().unsubscribe(this.handleUserScheduleChange))
  }

  private handleUserScheduleChange = (payload: ScheduleChangePayload) => {
    this.shellOneTimePeriods = payload.userSchedule.oneTimePeriods.map(convertOneTimePeriodWithIdToShellOneTimePeriod)
  }

  private updateModifiedOneTimePeriodData(property: string, value: string | boolean) {
    this.shellOneTimePeriod = { ...this.shellOneTimePeriod, [property]: value }
  }

  private adjustEndTime() {
    if (this.shellOneTimePeriod.startDay === this.shellOneTimePeriod.endDay) {
      // if the start time is after the end time, adjust the end time to the next available time and hide invalid end time options
      this.endTimeOptions = getValidEndTimeOptions(this.shellOneTimePeriod.startTime, this.endTimeOptions)
      this.updateModifiedOneTimePeriodData(
        'endTime',
        getNextValidEndTime(this.shellOneTimePeriod.startTime, this.shellOneTimePeriod.endTime, this.endTimeOptions),
      )
    } else {
      // reset the end time options if the start date is different from the end date
      this.endTimeOptions = getAllEndTimeOptions()
    }
  }

  // if the start date is after the end date, adjust the end date to match the start date
  private adjustEndDate() {
    if (this.shellOneTimePeriod.startDay > this.shellOneTimePeriod.endDay) {
      this.updateModifiedOneTimePeriodData('endDay', this.shellOneTimePeriod.startDay)
    }
  }

  private handleNameInputChange(event: ChangeEvent<HTMLInputElement>) {
    this.updateModifiedOneTimePeriodData('name', event.currentTarget.value)
  }

  private handleStartDateChange(event: ChangeEvent<HTMLInputElement>) {
    this.updateModifiedOneTimePeriodData('startDay', event.currentTarget.value)
    this.adjustEndDate()
    this.adjustEndTime()
  }

  private handleEndDateChange(event: ChangeEvent<HTMLInputElement>) {
    this.updateModifiedOneTimePeriodData('endDay', event.currentTarget.value)
    this.adjustEndTime()
  }

  private handleDateRangeChange(event: CustomEvent<{ start: string; end: string }>) {
    this.updateModifiedOneTimePeriodData('startDay', event.detail.start)
    this.updateModifiedOneTimePeriodData('endDay', event.detail.end)
  }

  private handleStartTimeChange(event: ChangeEvent<HTMLSelectElement>) {
    this.updateModifiedOneTimePeriodData('startTime', event.currentTarget.value)
    this.adjustEndTime()
  }

  private handleEndTimeChange(event: ChangeEvent<HTMLSelectElement>) {
    this.updateModifiedOneTimePeriodData('endTime', event.currentTarget.value)
    this.adjustEndTime()
  }

  private handleDNDCheckboxChange(event: ChangeEvent<CheckboxComponent>) {
    this.updateModifiedOneTimePeriodData('dndEnabled', event.target.checked)
  }

  private handleCancelClick() {
    this.dispatchEvent(new CustomEvent(CLOSE_CUSTOM_HOURS_MODAL))
  }

  private handleAlldayCheckboxChange(event: ChangeEvent<CheckboxComponent>) {
    // if the all day checkbox is unchecked, reset the end time options
    if (!event.target.checked) {
      this.endTimeOptions = getAllEndTimeOptions()
    }
    this.shellOneTimePeriod = event.target.checked
      ? {
          ...this.shellOneTimePeriod,
          allDay: true,
          startTime: initialTimeOfTheDay,
          endTime: lastTimeOfTheDay,
        }
      : {
          ...this.shellOneTimePeriod,
          allDay: false,
          startTime: '',
          endTime: '',
        }
  }

  private validateForm() {
    // verify if all the required fields are filled
    const requiredFieldsFilled = hasAllRequiredCustomHoursFieldsFilled(this.shellOneTimePeriod)
    // verify that the oneTimePeriod does not overlap with any other oneTimePeriod
    this.hasOverlap = oneTimePeriodHasOverlap(this.shellOneTimePeriod, this.shellOneTimePeriods)

    if (this.originalShellOneTimePeriod) {
      // verify if the original oneTimePeriod has been modified when editing
      const isDirty = !compareValues(this.shellOneTimePeriod, this.originalShellOneTimePeriod)
      this.isFormValid = requiredFieldsFilled && isDirty && !this.hasOverlap
    } else {
      this.isFormValid = requiredFieldsFilled && !this.hasOverlap
    }
  }

  private handleConfirmClick() {
    this.dispatchEvent(
      new CustomEvent<NewOneTimePeriodEvent>(EDIT_OR_ADD_ONETIMEPERIOD, {
        detail: { oneTimePeriod: convertShellOneTimePeriodToOneTimePeriodWithId(this.shellOneTimePeriod) },
      }),
    )
  }

  private renderSelectOption(value: string, displayValue: string, hidden?: boolean) {
    return html`<chameleon-option class=${hidden ? 'hidden' : ''} value=${value}>${displayValue}</chameleon-option>`
  }

  private renderCustomHoursNameInput() {
    return html`
      <div class="name-input-section">
        <chameleon-text-field
          placeholder=${t('Enter a name')}
          fullwidth
          value=${this.shellOneTimePeriod.name}
          @change=${this.handleNameInputChange}
        >
          ${t('Custom hours name')}
        </chameleon-text-field>
      </div>
    `
  }

  private renderAllDayOrStartDateSection() {
    return html`
      <div class="all-day-start-section date-time-container">
        ${this.shellOneTimePeriod.allDay
          ? html`${this.renderDateRangePicker()}`
          : html` ${this.renderDatePicker('start')}
              <chameleon-select
                in-dialog
                class="start-time-select"
                selected-value=${this.shellOneTimePeriod.startTime}
                @change=${this.handleStartTimeChange}
              >
                <chameleon-option value="" class="hidden">${t('Time')}</chameleon-option>
                ${this.startTimeOptions.map(value => this.renderSelectOption(value, formatISOTime(value)))}
              </chameleon-select>`}
        <chameleon-checkbox
          class="all-day-checkbox"
          @change=${this.handleAlldayCheckboxChange}
          ?checked=${this.shellOneTimePeriod.allDay}
          >${t('All day')}</chameleon-checkbox
        >
      </div>
    `
  }

  private renderDateRangePicker() {
    // The oneTimePeriod must be at most one year long.
    // The max value cannot be used for now in the date range picker as it cannot dynamically calculate the max end date based on the start date while the calendar is opened.
    // Chameleon will add a maximun length attribute for this use case https://jira.ops.expertcity.com/browse/CHAMELEON-3250
    return html`
      <chameleon-date-range-picker
        input-label=${t('Date range')}
        locale=${getUserLocaleInDatePickerFormat()}
        timezone=${getUserTimezone()}
        calendar-toggle-label=${t('Toggle date range calendar')}
        min=${getCurrentLocalDate()}
        start=${this.shellOneTimePeriod.startDay}
        end=${this.shellOneTimePeriod.endDay}
        @change=${this.handleDateRangeChange}
      ></chameleon-date-range-picker>
    `
  }

  private renderEndDateSection() {
    return html`
      <div class="end-section date-time-container">
        ${this.renderDatePicker('end')}
        <chameleon-select
          in-dialog
          class="end-time-select"
          selected-value=${this.shellOneTimePeriod.endTime}
          @change=${this.handleEndTimeChange}
        >
          <chameleon-option value="" class="hidden">${t('Time')}</chameleon-option>
          ${this.endTimeOptions.map(({ value, hidden }) =>
            this.renderSelectOption(value, formatISOTime(value), hidden),
          )}
        </chameleon-select>
      </div>
    `
  }

  private renderDatePicker(calendarType: 'start' | 'end') {
    return html`
      <chameleon-date-picker
        input-label=${calendarType === 'start' ? t('Start') : t('End')}
        class=${calendarType === 'start' ? 'start-date-picker' : 'end-date-picker'}
        locale=${getUserLocaleInDatePickerFormat()}
        timezone=${getUserTimezone()}
        calendar-toggle-label=${calendarType === 'start'
          ? t('Toggle start date calendar')
          : t('Toggle end date calendar')}
        min=${calendarType === 'start'
          ? getCurrentLocalDate()
          : getMinimumCalendarEndDate(this.shellOneTimePeriod.startDay)}
        max=${calendarType === 'end' ? getMaximumCalendarEndDate(this.shellOneTimePeriod.startDay) : ''}
        value=${calendarType === 'start' ? this.shellOneTimePeriod.startDay : this.shellOneTimePeriod.endDay}
        @change=${calendarType === 'start' ? this.handleStartDateChange : this.handleEndDateChange}
      ></chameleon-date-picker>
    `
  }

  private renderDNDSection() {
    return html` <div class="dnd-section">
      <chameleon-checkbox ?checked=${this.shellOneTimePeriod.dndEnabled} @change=${this.handleDNDCheckboxChange}
        >${t('Do not disturb during these hours')}</chameleon-checkbox
      >
      <chameleon-typography variant="caption-medium" color="type-color-secondary" class="dnd-description"
        >${t('Automatically turn off notifications for this time range.')}</chameleon-typography
      >
    </div>`
  }

  private renderAlert() {
    return this.hasOverlap
      ? html` <chameleon-alert-v2 variant="danger"
          >${t(`You can't set custom hours that overlap previously scheduled custom hours`)}</chameleon-alert-v2
        >`
      : nothing
  }

  render() {
    return html`
      <form>
        <chameleon-dialog open size="large">
          <div class="modal-header" slot="title">
            <chameleon-typography variant="heading-small">${t('Set your custom hours')}</chameleon-typography>
          </div>
          <div class="modal-body">
            ${this.renderAlert()}${this.renderCustomHoursNameInput()}${this.renderAllDayOrStartDateSection()}
            ${this.shellOneTimePeriod.allDay ? nothing : this.renderEndDateSection()}${this.renderDNDSection()}
          </div>
          <div class="modal-footer" slot="actions">
            <chameleon-button
              size="medium"
              @click=${this.handleConfirmClick}
              class="confirm-button"
              ?disabled=${!this.isFormValid}
              >${t('Confirm')}</chameleon-button
            >
            <chameleon-button size="medium" variant="tertiary" @click=${this.handleCancelClick} class="cancel-button"
              >${t('Cancel')}</chameleon-button
            >
          </div>
        </chameleon-dialog>
      </form>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-schedule-custom-hours-modal': GoToScheduleCustomHoursModal
  }
}
