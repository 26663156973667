import type { ShellUserAccount, ShellUserAccountSettings } from '../core/models'
import { type AccountAndPbxInfo } from '../services/account/models'
import { getAccountAndPBXFromLocalStorage } from '../services/context'
import { type ProductTrial } from '../services/context/models'
import { getFromLocalStorage } from './dom-helpers'
import { getShellApiInstance } from './shell-api-helpers'
import { getAccountFromContext } from './shell-api-helpers/context'
/**
 * getCurrentAccountEntitlements will return entitlements from the selected account
 */
export const getCurrentAccountEntitlements = (): string[] | undefined => {
  const user = getShellApiInstance().user
  const accountKey = getAccountFromContext()?.key ?? null

  if (accountKey) {
    return user
      .getAccountByKey(accountKey)
      ?.licenses.map(license => license.entitlements)
      .flat()
  }

  return []
}

/**
 * getCurrentAccountRoles will return roles from the selected account
 */
export const getCurrentAccountRoles = (): string[] | undefined => {
  const user = getShellApiInstance().user
  const accountKey = getAccountFromContext()?.key ?? null

  if (accountKey) {
    return user
      .getAccountByKey(accountKey)
      ?.licenses.map(license => license.roles)
      .flat()
  }

  return []
}

export const getCurrentAccountSettings = (): ShellUserAccountSettings | undefined => {
  const user = getShellApiInstance().user
  const accountKey = getAccountFromContext()?.key ?? null

  let account
  if (accountKey) {
    account = user.getAccountByKey(accountKey)
  }

  return account?.settings
}

export const getAccounts = (): readonly ShellUserAccount[] => getShellApiInstance().user.accounts

export const getStoredCurrentAccount = (externalUserKey: string): AccountAndPbxInfo =>
  getAccountAndPBXFromLocalStorage(externalUserKey)

/**
 * Checks if the user instance has any trial data
 */
export const isTrialUser = (): boolean => {
  const productTrial = getProductTrialFromLocalStorage()
  return (productTrial?.trial && productTrial?.active) ?? false
}

/**
 * Checks if the user instance has any free account data
 */
export const isFreeUser = (): boolean => {
  const productTrial = getProductTrialFromLocalStorage()
  return (productTrial?.free && productTrial?.active) ?? false
}

/**
 * Gets the end date of the user's active trial
 */
export const getProductTrialEndDate = () => {
  const productTrial = getProductTrialFromLocalStorage()
  return productTrial?.endDate ? new Date(productTrial.endDate) : undefined
}

export const getProductTrialFromLocalStorage = (): ProductTrial | undefined => {
  const productTrialJSON = getFromLocalStorage('productTrial')

  if (productTrialJSON) {
    try {
      return JSON.parse(productTrialJSON)
    } catch {
      return undefined
    }
  }
  return
}

export const isUserAuthenticated = () => !!getShellApiInstance().user?.key

export const isUserOwnedEmail = (email: string) => {
  const user = getShellApiInstance()?.user
  return user?.emails?.some(userEmail => userEmail.value === email) ?? false
}

/**
 * @returns the user timezone
 */
export const getUserTimezone = () => {
  const user = getShellApiInstance().user
  return user.location.timeZone
}
