import { html } from 'lit-element'
import { ShellElement } from '../../common/shell-element'
import type { I18NEvents } from '../../services/i18n'
import { setDocumentLocale, getCurrentLocale, I18NNamespace } from '../../services/i18n'
import { getEventBus } from '../../services/namespaces/event-bus'
import { updateChameleonThemeProvider, subscribeToMsTeamThemeChanged, saveChameleonTheme } from '../../services/theme'
import { getDocument } from '../../common/dom-helpers'
import { CHAMELEON_THEME_PROVIDER } from '../../common/container'
import { ShellNamespace } from '../../services/shell-namespace'
import type { Theme, shellEvents } from '../../services/shell-namespace'
import { getExternalInterface } from '../../services/external-interface'
import { addDefaultActions, addDefaultContexts } from '../context-menu/shell-context-menu'
import {
  type ContextItem,
  ContextMenuIntegrationSchema,
  type ShellAction,
  type ShellContextMenuEvent,
} from '@goto/shell-common'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import { getContextMenuActions, getContextMenuContextItems } from '@goto/shell-utils'
import { actionsToMenuItems } from '../context-menu/action-utils'
import { type GoToShellContextMenuItem } from '../context-menu/models'
import { GoToShellContextMenu } from '../context-menu/context-menu'
import { type DisplayableShellAction } from '../../extensions/models'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { ZIndex } from '../../common/z-index'
import { isPasswordInput } from '../context-menu/clipboard-utils'

const convertActionToDisplayableAction = (
  shellAction: ShellAction,
  labelResolver: (key: string) => string,
): DisplayableShellAction => ({
  ...shellAction,
  children: shellAction.children
    ? shellAction.children.map(action => convertActionToDisplayableAction(action, labelResolver))
    : [],
  label: shellAction.label ?? labelResolver(shellAction.labelKey ?? ''),
})

/**
 * Element that allows to interact with the shell instance in a document context
 */
export class GoToShellController extends ShellElement {
  static readonly tagName = 'goto-shell-controller'
  private initialized = false

  /**
   * initialize the controller with shell.  Shell API must be available prior calling this
   */
  public initializeController() {
    this.initialized = true
    if (this.isConnected) {
      this.init()
    }
  }

  private init() {
    this.updateLocale()
    this.addContextMenuListener()
    this.subscribeToLocaleChange()
    this.subscribeToThemeChange()
    if (getExternalInterface().isIntegration) {
      subscribeToMsTeamThemeChanged()
    }
  }

  connectedCallback() {
    super.connectedCallback()
    if (this.initialized) {
      this.init()
    }
  }

  render() {
    return html`<slot></slot>`
  }

  private readonly updateLocale = () => {
    setDocumentLocale(getCurrentLocale(), document)
  }

  private subscribeToLocaleChange() {
    const { localeChanged } = getEventBus().subscribeTo<typeof I18NNamespace, typeof I18NEvents>(I18NNamespace)
    localeChanged.on(this.updateLocale)
    this.unsubscribeFunctions.push(() => {
      localeChanged.removeListener(this.updateLocale)
    })
  }

  private addContextMenuListener() {
    this.addEventListener('contextmenu', this.handleContextMenu)
    this.unsubscribeFunctions.push(() => {
      this.removeEventListener('contextmenu', this.handleContextMenu)
    })
  }

  private readonly getActionsForContexts = async (contextItems: ContextItem[]) => {
    const actions: DisplayableShellAction[] = []
    const extensionIntegrations = await getShellApiInstance().extensions.queryInterface(ContextMenuIntegrationSchema)
    return extensionIntegrations.reduce((result, integration) => {
      const resultIntegrations = integration.impl
        .getContextMenuActionsForContext(contextItems)
        ?.map(action => convertActionToDisplayableAction(action, integration.extension.getString))
      return [...result, ...(resultIntegrations ?? [])]
    }, actions)
  }

  private canShowShellContextMenu(event: PointerEvent): boolean {
    const eventTarget = (event.composedPath()[0] ?? event.target) as HTMLElement
    const showNativeMenu = event.shiftKey || (event as ShellContextMenuEvent).showNativeMenu
    return !(showNativeMenu || event.defaultPrevented || isPasswordInput(eventTarget))
  }

  private readonly handleContextMenu = async (event: PointerEvent) => {
    if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_CONTEXT_MENU)) {
      if (this.canShowShellContextMenu(event)) {
        addDefaultContexts(event)
        addDefaultActions(event)
        const eventActions = getContextMenuActions(event).map(action =>
          convertActionToDisplayableAction(action, (key: string) => getTranslation(key)),
        )
        if (eventActions.length) {
          event.preventDefault()
        }
        const contextItems = getContextMenuContextItems(event)
        const integrationActions = await this.getActionsForContexts(contextItems)
        const actions = [...eventActions, ...integrationActions]
        this.showContextMenu(event, actionsToMenuItems(actions))
      }
    }
  }

  private readonly showContextMenu = (contextMenuEvent: PointerEvent, items: GoToShellContextMenuItem[]) => {
    const contextMenuElement = document.createElement(GoToShellContextMenu.tagName)

    contextMenuElement.menuItems = items
    contextMenuElement.style.zIndex = ZIndex.contextMenu
    contextMenuElement.style.position = 'absolute'
    this.appendChild(contextMenuElement)
    setTimeout(() => {
      const posX = Math.max(0, Math.min(contextMenuEvent.pageX, this.clientWidth - contextMenuElement.clientWidth))
      const posY = Math.max(0, Math.min(contextMenuEvent.pageY, this.clientHeight - contextMenuElement.clientHeight))
      contextMenuElement.style.left = `${posX}px`
      contextMenuElement.style.top = `${posY}px`
    })

    const removeMenu = () => {
      contextMenuElement.remove()
      removeListeners()
    }
    const clickHandler = () => {
      removeMenu()
    }
    const keyHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        removeMenu()
      }
    }
    const addListeners = () => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
      setTimeout(() => document.addEventListener('contextmenu', clickHandler))
    }

    const removeListeners = () => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('contextmenu', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    }
    addListeners()
  }

  private readonly updateTheme = (theme: Theme) => {
    const chameleonThemeProvider = getDocument().getElementsByTagName(CHAMELEON_THEME_PROVIDER)[0]
    updateChameleonThemeProvider(chameleonThemeProvider, theme)
    saveChameleonTheme(theme)
  }

  private subscribeToThemeChange() {
    const { themeChanged } = getEventBus().subscribeTo<typeof ShellNamespace, typeof shellEvents>(ShellNamespace)
    themeChanged.on(this.updateTheme)
    this.unsubscribeFunctions.push(() => {
      themeChanged.removeListener(this.updateTheme)
    })
  }
}
declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-shell-controller': GoToShellController
  }
}
