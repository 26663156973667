import { html, state } from 'lit-element'
import { t } from '../../directives/translate'
import { repeat } from 'lit-html/directives/repeat'
import presenceSelectorStyles from './presence-selector.styles.scss'
import { getShellAnalytics, getShellApiInstance } from '../../common/shell-api-helpers'
import type { Listener } from '../../services/namespaces'
import {
  type IShellPresenceService,
  type PresenceEvents,
  type PresenceUpdated,
  type Status,
  type UserPresence,
  type UserPresenceUpdated,
  Appearance,
  UserAppearance,
  UserDoNotDisturb,
  convertAppearanceToPresence,
  PresenceServiceNamespace,
} from '../../services/presence'

import { getAppearanceTranslationKey } from '../../common/presence-helpers'
import { getShellLogger } from '../../common/logger'
import { assert } from '../../common/assert-helpers'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { nothing } from 'lit-html'
import { ShellElement } from '../../common/shell-element'
import { type ChangeEvent } from 'react'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { SVG_SYNC_FILLED } from '@getgo/chameleon-icons'

export class GotoPresenceSelector extends ShellElement {
  static readonly tagName = 'goto-presence-selector'

  private readonly currentUserExternalUserKey = getShellApiInstance().user.key ?? ''
  private readonly presence = getShellApiInstance().presence
  @state() private selectedAppearance = Appearance.OFFLINE
  @state() private status: Status | undefined = undefined

  private readonly appearanceList = [
    Appearance.ONLINE,
    Appearance.AWAY,
    Appearance.BUSY,
    Appearance.DO_NOT_DISTURB,
    Appearance.OFFLINE,
  ]

  static get styles() {
    return presenceSelectorStyles
  }

  connectedCallback() {
    super.connectedCallback()
    if (this.currentUserExternalUserKey && this.presence) {
      this.subscribeToPresence(this.presence)
      this.listenForPresenceUpdatedEvent()
    }
  }

  private subscribeToPresence(presence: IShellPresenceService) {
    presence
      .subscribe(this.currentUserExternalUserKey)
      .then(() => {
        this.unsubscribeFunctions.push(() => {
          presence.unsubscribe(this.currentUserExternalUserKey)
        })
      })
      .catch(() => {
        getShellLogger().error(
          `failed to subscribe to presence for external user key ${this.currentUserExternalUserKey}`,
        )
      })
  }

  private listenForPresenceUpdatedEvent() {
    const { eventBus } = getShellApiInstance()
    const { userPresenceUpdate } = eventBus.subscribeTo<typeof PresenceServiceNamespace, typeof PresenceEvents>(
      PresenceServiceNamespace,
    )

    const presenceUpdatedHandler: Listener<PresenceUpdated> = (payload: UserPresenceUpdated) => {
      const { userAppearance, appearance, status, userDoNotDisturb } = payload

      // Condition required to select Auto-update
      // `userAppearance === NONE` is used by Auto-update and DoNotDisturb.
      // To differentiate Auto-update with DoNotDisturb, we use another property `userDoNotDisturb` set to NONE
      if (userAppearance === UserAppearance.NONE && userDoNotDisturb === UserDoNotDisturb.NONE) {
        this.selectedAppearance = Appearance.AUTO_UPDATE
        this.status = status
      } else if (appearance) {
        this.selectedAppearance = appearance
        this.status = status
      }
    }

    userPresenceUpdate.on(presenceUpdatedHandler)

    this.unsubscribeFunctions.push(() => {
      userPresenceUpdate.removeListener(presenceUpdatedHandler)
    })
  }

  private setNewPresence(userPresence: UserPresence) {
    try {
      assert(this.presence, 'shell presence service is undefined')
      this.presence.setUserPresence(userPresence)
    } catch {
      //to be handled in https://jira.ops.expertcity.com/browse/SCORE-1417
    }
  }

  // The userStatus is an automatic field that should not be modified according to the CORE team. It should not be included in the arguments here.
  private setArgumentsForNewPresence(appearance: Appearance) {
    switch (appearance) {
      case Appearance.ONLINE:
        this.setNewPresence({
          userAppearance: getFeatureFlagValue(FeatureFlagsVariations.SHELL_ENABLE_PRESENCE_AUTO_UPDATE)
            ? UserAppearance.ONLINE
            : UserAppearance.NONE,
          userDoNotDisturb: UserDoNotDisturb.NONE,
        })
        break
      case Appearance.AWAY:
        this.setNewPresence({
          userAppearance: UserAppearance.AWAY,
          userDoNotDisturb: UserDoNotDisturb.NONE,
        })
        break
      case Appearance.BUSY:
        this.setNewPresence({
          userAppearance: UserAppearance.BUSY,
          userDoNotDisturb: UserDoNotDisturb.NONE,
        })
        break
      case Appearance.DO_NOT_DISTURB:
        this.setNewPresence({
          userAppearance: UserAppearance.NONE,
          userDoNotDisturb: UserDoNotDisturb.DO_NOT_DISTURB,
        })
        break
      case Appearance.OFFLINE:
        this.setNewPresence({
          userAppearance: UserAppearance.OFFLINE,
          userDoNotDisturb: UserDoNotDisturb.NONE,
        })
        break
      case Appearance.AUTO_UPDATE:
        this.setNewPresence({
          userAppearance: UserAppearance.NONE,
          userDoNotDisturb: UserDoNotDisturb.NONE,
        })
        break
    }
  }

  private handleChangeEvent(event: ChangeEvent<HTMLInputElement>) {
    getShellAnalytics().track('GoTo > Presence Selector', { action: 'Click', target: event.target.value })
    this.dispatchEvent(
      new CustomEvent('presenceClicked', {
        detail: { presence: event.target.value },
        bubbles: true,
        composed: true,
      }),
    )

    this.setArgumentsForNewPresence(event.target.value as unknown as Appearance)
  }

  private renderPresenceOption(appearance: Appearance) {
    if (appearance === Appearance.BUSY && !getFeatureFlagValue(FeatureFlagsVariations.SHELL_ADVANCED_PRESENCE_BUSY)) {
      return nothing
    }
    return html`
      <chameleon-option
        value=${appearance}
        data-test=${appearance}
        aria-label=${t(getAppearanceTranslationKey(appearance, true))}
      >
        ${t(getAppearanceTranslationKey(appearance, true))}
        <chameleon-presence-indicator
          presence=${convertAppearanceToPresence(appearance)}
          label=${getAppearanceTranslationKey(appearance, true)}
          slot="start"
        ></chameleon-presence-indicator>
      </chameleon-option>
    `
  }

  private renderAutoUpdateOption() {
    if (!getFeatureFlagValue(FeatureFlagsVariations.SHELL_ENABLE_PRESENCE_AUTO_UPDATE)) {
      return nothing
    }
    return html` <chameleon-option
      style="height:unset;"
      value=${Appearance.AUTO_UPDATE}
      data-test=${Appearance.AUTO_UPDATE}
      aria-label=${t('Auto-update')}
      id="auto-update"
    >
      ${t('Auto-update')}
      <chameleon-svg class="sync-svg" slot="start"> ${unsafeSVG(SVG_SYNC_FILLED)}</chameleon-svg>
    </chameleon-option>`
  }

  render() {
    return html`
      <chameleon-select
        class="presence-selector"
        no-border
        size="small"
        selected-value=${this.selectedAppearance}
        @change=${this.handleChangeEvent}
      >
        ${repeat(
          this.appearanceList,
          appearance => appearance,
          appearance => this.renderPresenceOption(appearance),
        )}
        ${this.renderAutoUpdateOption()}
        <div slot="selected-value" class="selected-value">
          ${t(getAppearanceTranslationKey(this.selectedAppearance, true, this.status))}
        </div>
      </chameleon-select>
    `
  }
}
declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-presence-selector': GotoPresenceSelector
  }
}
