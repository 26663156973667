export enum ZIndex {
  /**
   * To appear over every elements except the Windows Desktop App topbar and context menu
   */
  tooltip = '1100',
  /**
   * To appear on top of everything
   */
  contextMenu = '1000000',
}
