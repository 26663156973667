import { getPbxFromContext } from '../../common/shell-api-helpers/context'
import { type UserSchedule } from '../../packages/schedule-settings/schedule-manager/models'
import { authenticatedFetch } from '../auth/authenticatedFetch'
import { getGtcBffOrganizationInformationUrl } from '../gtc-bff/gtc-bff'

export class ScheduleService {
  async fetchUserSchedule(): Promise<UserSchedule> {
    const baseUrl = this.getUserScheduleUrl()

    const response = await authenticatedFetch<UserSchedule>(baseUrl, { method: 'GET' })
    return await response.json()
  }

  async updateUserSchedule(userSchedule: UserSchedule): Promise<UserSchedule> {
    const baseUrl = this.getUserScheduleUrl()

    const response = await authenticatedFetch<UserSchedule>(baseUrl, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userSchedule),
    })
    return await response.json()
  }

  private getUserScheduleUrl(): string {
    const currentAccount = getPbxFromContext()

    if (!currentAccount?.id) {
      throw new Error('Cannot call ScheduleService. Account id is missing')
    }

    return `${getGtcBffOrganizationInformationUrl(currentAccount.id)}/user-schedule`
  }
}
