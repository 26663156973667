import { formatDateTime, formatDateTimeRange, formatLocale, getCurrentLocale } from '../../services/i18n'
import { type WorkPeriod } from './schedule-manager/models'

import { type EndTimeOptions, timeFormatOptions } from './schedule-settings-models'

export const timeIntervalMinutes = 15

const isISOTimeStringValid = (timeString: string) => {
  const regExp = /^([0-9]|[01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/
  return regExp.test(timeString)
}

const parseTime = (timeString: string) => {
  if (!isISOTimeStringValid(timeString)) {
    return null
  }

  return timeString.split(':').map(Number)
}

export const newDateTime = (ISOTime: string) => {
  const parsedTime = parseTime(ISOTime)
  if (parsedTime) {
    const [hours, minutes, seconds] = parsedTime
    return new Date().setHours(hours, minutes, seconds, 0)
  }
}

export const formatISOTime = (ISOTime: string): string => {
  const dateTime = newDateTime(ISOTime) ?? new Date().setHours(0, 0, 0, 0)
  return formatDateTime(dateTime, timeFormatOptions)
}

export const formatISOTimeRange = (ISOStartTime: string, ISOEndTime: string): string => {
  const parsedStartTime = parseTime(ISOStartTime)
  const parsedEndTime = parseTime(ISOEndTime)
  if (parsedStartTime && parsedEndTime) {
    const [startHours, startMinutes, startSeconds] = parsedStartTime
    const [endHours, endMinutes, endSeconds] = parsedEndTime
    return formatDateTimeRange(
      new Date().setHours(startHours, startMinutes, startSeconds),
      new Date().setHours(endHours, endMinutes, endSeconds),
      timeFormatOptions,
    )
  }

  return formatDateTime(new Date().setHours(0, 0, 0, 0), timeFormatOptions)
}

export const initialTimeOfTheDay = '00:00:00'
export const lastTimeOfTheDay = '23:59:59'

export const getListOfTimeForOneDayWithInterval = (intervalInMinutes: number) => {
  const result = []

  const currentTime = new Date()
  currentTime.setHours(0, 0, 0, 0)

  const originalDay = currentTime.getDay()

  while (currentTime.getDay() === originalDay) {
    result.push(currentTime.toTimeString().split(' ')[0])
    currentTime.setMinutes(currentTime.getMinutes() + intervalInMinutes)
  }
  return result
}

//TODO: update with assertSchema in this story: https://jira.ops.expertcity.com/browse/SCORE-3512
export const isWorkPeriod = (partialWorkPeriod: Partial<WorkPeriod>): partialWorkPeriod is WorkPeriod =>
  !!(partialWorkPeriod.dayOfWeek && partialWorkPeriod.startTime && partialWorkPeriod.endTime)

export const dateTimeIntervalsOverlap = (startA: number, endA: number, startB: number, endB: number) =>
  startA < endB && endA > startB

/**
 * Maps the end time options with a hidden property for the end time dropdown
 * @returns the list of end time options
 */
export const getAllEndTimeOptions = (): EndTimeOptions[] =>
  getListOfTimeForOneDayWithInterval(timeIntervalMinutes)
    .map(int => ({ value: int, hidden: false }))
    .concat({ value: lastTimeOfTheDay, hidden: false })

/**
 * Sets to true the hidden property on the invalid end time options based on the start time
 * @param startTime the currently selected start time
 * @param endTimeOptions the possible end time options
 * @returns the list of end time options with the hidden property set
 */
export const getValidEndTimeOptions = (startTime: string, endTimeOptions: EndTimeOptions[]) => {
  const indexTime = endTimeOptions.findIndex(({ value }) => value === startTime)
  return endTimeOptions.map((option, index) => ({ ...option, hidden: index <= indexTime }))
}

/**
 * Gets the next valid end time based on the currently selected start time and end time
 * @param startTime the currently selected start time
 * @param endTime the currently selected end time
 * @param endTimeOptions the possible end time options
 * @returns the next valid end time
 */
export const getNextValidEndTime = (startTime: string, endTime: string, endTimeOptions: EndTimeOptions[]): string => {
  const startIndex = endTimeOptions.findIndex(({ value }) => value === startTime)
  const endIndex = endTimeOptions.findIndex(({ value }) => value === endTime)
  if (startIndex !== -1 && endIndex !== -1 && startIndex >= endIndex) {
    return endTimeOptions[startIndex + 1].value
  }
  return endTime
}

/**
 * Gets the current local date of the user in the format yyyy-mm-dd
 * @returns the formatted current date
 */
export const getCurrentLocalDate = () => {
  const date = new Date()
  const year = date.getFullYear().toString().padStart(4, '0')
  // getMonth returns the month of the date represented by a Date object, as a zero-based index, so we need to add 1 to get the correct month
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

/**
 * @returns the user locale in the format accepted by chameleon-date-picker
 */
export const getUserLocaleInDatePickerFormat = () => formatLocale(getCurrentLocale(), '-')

/**
 * Gets the minimum calendar end date based on the start day
 * @param startDay optional start day
 * @returns either the start day if provided or the current date in ISO format
 */
export const getMinimumCalendarEndDate = (startDay?: string) => (startDay ? startDay : getCurrentLocalDate())

/**
 * Calculates the maximun calendar end date a year after the start day or the current date
 * @param startDay optional start day
 * @returns the maximum calendar end date
 */
export const getMaximumCalendarEndDate = (startDay?: string) => {
  const currentStartDate = startDay ? new Date(startDay) : new Date()
  currentStartDate.setFullYear(currentStartDate.getFullYear() + 1)
  return currentStartDate.toISOString().split('T')[0]
}
