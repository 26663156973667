import { loadScript } from '@goto/shell'

const contactCenterAgentBaseUrl = '/experiences/cc-agent'

export const loadContactCenterAgentService = async () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any)['@ucc/cc-agent/service']) {
    return
  }
  await loadScript(`${contactCenterAgentBaseUrl}/service.js`, {
    resolveWithGlobal: '@ucc/cc-agent/service',
  })
}

export const isLocalhost = () => location.hostname === 'localhost' || location.hostname === '127.0.0.1'