import { addContextMenuContextItem } from '@goto/shell-utils'
import { addClipboardActions } from './clipboard-actions'
import { addSearchActions } from './search-actions'
import { type ShellContextMenuEvent } from '@goto/shell-common'
import { getWindow } from '../../common/dom-helpers'

/**
 * Adds the default contexts into the menu context event
 * @param event event on which to add the default context items
 * @returns the modified event
 */
export function addDefaultContexts(event: Event): Event {
  const str = (getWindow().getSelection()?.toString() ?? '').trim()
  if (str) {
    addContextMenuContextItem(event, 'string', str)
  }
  return event
}

/**
 * Adds the default actions that should be shown in context menus
 * @param event event on which to add the default actions for context menu
 * @returns modified event
 */
export function addDefaultActions(event: Event): Event {
  const shellEvent = event as ShellContextMenuEvent
  addClipboardActions(shellEvent)
  addSearchActions(shellEvent)
  return event
}
