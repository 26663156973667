/* istanbul ignore file */

import { getShellApiInstance } from '../common/shell-api-helpers'
import { isMainWindow } from '../services/container'
import { getFeatureFlagValue } from '../services/feature-flags'
import { FeatureFlagsVariations } from '../services/feature-flags/models'

const SHELL_SW_REL_PATH = '/shell-sw.js'

const registerServiceWorker = async () => {
  if (!isMainWindow() || !('serviceWorker' in navigator)) {
    return
  }

  try {
    const registration = await navigator.serviceWorker.register(SHELL_SW_REL_PATH)

    // Channel used to communicate with the service worker. See routes\access-token-interceptor.ts
    const channel = new BroadcastChannel('shell-serviceworker-channel')

    const postAccessToken = () => {
      const serviceWorker = registration.active ?? registration.waiting ?? registration.installing
      const token = getShellApiInstance().auth.getToken()?.access_token ?? ''

      serviceWorker?.postMessage({ token, message: 'post-accesstoken' })
    }

    channel.addEventListener('message', event => {
      if (event.data === 'request-accesstoken') {
        postAccessToken()
      }
    })
  } catch (err) {
    console.log('SW registration failed: ', err)
  }
}

export const registerServiceWorkerOnWindowLoad = () => {
  if (!getFeatureFlagValue(FeatureFlagsVariations.SHELL_DISABLE_SERVICE_WORKER)) {
    window.addEventListener('load', () => {
      registerServiceWorker()
    })
  }
}

export const unregisterServiceWorker = async () => {
  if (!isMainWindow() || !('serviceWorker' in navigator)) {
    return
  }

  try {
    const registration = await navigator.serviceWorker.getRegistration(SHELL_SW_REL_PATH)
    if (registration) {
      const succeed = await registration.unregister()
      if (!succeed) {
        throw 'Failed to unregister service worker'
      }
    }
  } catch (err) {
    console.log('SW unregistration failed: ', err)
  }
}
