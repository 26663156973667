import { getShellLogger } from '../../../common/logger'
import { randomID } from '../../../common/helpers'
import { getDefaultUserSchedule, getScheduleSavedStorageKey } from '../helpers'
import {
  type WorkPeriod,
  type UserSchedule,
  type ShellUserSchedule,
  type OneTimePeriodWithId,
  type ScheduleChangeListener,
} from './models'
import { ScheduleService } from '../../../services/schedule-service/schedule.service'
import { cloneDeep } from '../../../core/helpers/clone'
import { getFromLocalStorage, setToLocalStorage } from '../../../common/dom-helpers'
import { createDefaultWorkPeriods } from '../schedule-settings-models'
import { getShellAnalytics } from '../../../common/shell-api-helpers'

enum ScheduleProps {
  ENABLED = 'enabled',
  DND_AUTOMATION_ENABLED = 'dndAutomationEnabled',
  WORK_PERIODS = 'workPeriods',
  ONE_TIME_PERIODS = 'oneTimePeriods',
}

type SchedulePropsValueType = boolean | WorkPeriod[] | OneTimePeriodWithId[]

export class ScheduleManager {
  private persistedUserSchedule: ShellUserSchedule
  private modifiedUserSchedule: ShellUserSchedule
  private isDirty: boolean = false

  private scheduleService: ScheduleService

  private subscriptions: ScheduleChangeListener[] = []

  constructor() {
    this.scheduleService = new ScheduleService()

    this.persistedUserSchedule = this.convertUserScheduleToShellUserSchedule(getDefaultUserSchedule())
    this.modifiedUserSchedule = cloneDeep(this.persistedUserSchedule)

    this.fetchUserSchedule()
  }

  public subscribe(cb: ScheduleChangeListener): void {
    this.subscriptions.push(cb)
    cb({
      userSchedule: cloneDeep(this.modifiedUserSchedule),
      state: {
        isDirty: this.isDirty,
      },
    })
  }

  public unsubscribe(cb: ScheduleChangeListener): void {
    this.subscriptions = this.subscriptions.filter(subscription => subscription !== cb)
  }

  /**
   * Resets the value of the modified user schedule
   * @returns the current unmodified user schedule
   */
  public resetUserSchedule() {
    this.updateModifiedUserSchedule(this.persistedUserSchedule, false)
  }

  /**
   * Updates the enabled status of the modified user schedule
   * @param enabled the new enabled status
   */
  public updateUserScheduleEnabled(enabled: boolean): void {
    if (this.modifiedUserSchedule.enabled !== enabled) {
      this.setModifiedUserSchedule(ScheduleProps.ENABLED, enabled)
    }
  }

  /**
   * Updates the dndAutomationEnabled status of the modified user schedule
   * This boolean is set for the entire work schedule period
   * @param dndAutomationEnabled the new dndAutomationEnabled status
   */
  public updateDndAutomationEnabled(dndAutomationEnabled: boolean): void {
    if (this.modifiedUserSchedule.dndAutomationEnabled !== dndAutomationEnabled) {
      this.setModifiedUserSchedule(ScheduleProps.DND_AUTOMATION_ENABLED, dndAutomationEnabled)
    }
  }

  /**
   * Updates the work schedule of the modified user schedule
   * @param workSchedule the new work schedule
   */
  public updateWorkPeriods(workPeriods: WorkPeriod[]): void {
    this.setModifiedUserSchedule(ScheduleProps.WORK_PERIODS, workPeriods)
  }

  /**
   * Updates the custom schedule of the modified user schedule
   * @param customSchedule the new custom schedule
   */
  public updateOneTimePeriods(oneTimePeriods: OneTimePeriodWithId[]): void {
    this.setModifiedUserSchedule(ScheduleProps.ONE_TIME_PERIODS, oneTimePeriods)
  }

  /**
   * Saves the modified user schedule in the backend.
   */
  public async saveUserSchedule(): Promise<void> {
    try {
      const data = await this.scheduleService.updateUserSchedule(this.convertToUserSchedule(this.modifiedUserSchedule))
      getShellAnalytics().track('GoTo > Schedule settings - Save', {
        action: 'save successful',
        numberOneTimePeriods: this.modifiedUserSchedule.oneTimePeriods?.length,
        numberWorkPeriods: this.modifiedUserSchedule.workPeriods?.length,
      })
      this.persistedUserSchedule = this.convertUserScheduleToShellUserSchedule(data)
      this.updateModifiedUserSchedule(this.persistedUserSchedule, false)
      setToLocalStorage(getScheduleSavedStorageKey(), 'true')
    } catch (e) {
      getShellAnalytics().track('GoTo > Schedule settings - Save', {
        action: 'save failed',
      })
      getShellLogger().error('Error sending user schedule', e)
      throw e
    }
  }

  private async fetchUserSchedule() {
    try {
      const data = await this.scheduleService.fetchUserSchedule()
      const userHasSavedSchedule = getFromLocalStorage(getScheduleSavedStorageKey())
      if (data.workPeriods.length === 0 && !userHasSavedSchedule) {
        data.workPeriods = createDefaultWorkPeriods()
      }
      this.persistedUserSchedule = this.convertUserScheduleToShellUserSchedule(data)
      this.updateModifiedUserSchedule(this.persistedUserSchedule, false)
    } catch (e) {
      getShellLogger().error('Error getting user schedule', e)
    }
  }

  private convertUserScheduleToShellUserSchedule(userSchedule: UserSchedule): ShellUserSchedule {
    const oneTimePeriods = userSchedule.oneTimePeriods ?? []

    return {
      ...userSchedule,
      oneTimePeriods: oneTimePeriods.map(period => ({
        ...period,
        id: randomID(),
      })),
    }
  }

  private convertToUserSchedule(shellUserSchedule: ShellUserSchedule): UserSchedule {
    return {
      ...shellUserSchedule,
      oneTimePeriods: shellUserSchedule.oneTimePeriods?.map(({ id: _id, ...rest }) => rest),
    }
  }

  private updateModifiedUserSchedule(data: ShellUserSchedule, isDirty: boolean) {
    this.modifiedUserSchedule = cloneDeep(data)
    this.isDirty = isDirty
    this.subscriptions.forEach(subscription =>
      subscription({
        userSchedule: cloneDeep(data),
        state: {
          isDirty,
        },
      }),
    )
  }

  private setModifiedUserSchedule(scheduleProps: ScheduleProps, schedulePropsValue: SchedulePropsValueType) {
    switch (scheduleProps) {
      case ScheduleProps.ENABLED:
        this.modifiedUserSchedule.enabled = schedulePropsValue as boolean
        break
      case ScheduleProps.DND_AUTOMATION_ENABLED:
        this.modifiedUserSchedule.dndAutomationEnabled = schedulePropsValue as boolean
        break
      case ScheduleProps.WORK_PERIODS:
        this.modifiedUserSchedule.workPeriods = schedulePropsValue as WorkPeriod[]
        break
      case ScheduleProps.ONE_TIME_PERIODS:
        this.modifiedUserSchedule.oneTimePeriods = schedulePropsValue as OneTimePeriodWithId[]
        break
    }

    this.updateModifiedUserSchedule(this.modifiedUserSchedule, true)
  }
}

let scheduleManagerInstance: ScheduleManager

export const getScheduleManager = (): ScheduleManager => {
  if (!scheduleManagerInstance) {
    scheduleManagerInstance = new ScheduleManager()
  }
  return scheduleManagerInstance
}
