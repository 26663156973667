import { html, type PropertyValues, state } from 'lit-element'
import type { ApplicationsResponse, PopoverMenu, ApplicationItem } from '../popover-menu/models'
import { SVG_APP_SWITCHER_FILLED } from '@getgo/chameleon-icons'
import { authenticatedFetch } from '../../services/auth'
import { t } from '../../directives/translate'
import styles from './app-switcher.styles.scss'
import { environment } from '../../environments'
import type { I18NEvents, Locale } from '../../services/i18n'
import { getCurrentLocale, I18NNamespace } from '../../services/i18n'
import { getShellLogger } from '../../common/logger'
import { nothing } from 'lit-html'
import { getEventBus } from '../../services/namespaces/event-bus'
import { ShellElement } from '../../common/shell-element'
import { getJiveApiBaseUrl } from '../../core/environment'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'

export class GoToAppSwitcher extends ShellElement {
  static readonly tagName = 'goto-app-switcher'

  @state()
  private appSwitcherMenuItems: readonly PopoverMenu[] = []

  static get styles() {
    return styles
  }

  connectedCallback() {
    super.connectedCallback()
    this.addEventListener('keydown', event => this.handleKeyDown(event))
  }

  disconnectedCallback() {
    this.removeEventListener('keydown', event => this.handleKeyDown(event))
    super.disconnectedCallback()
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.code === 'Enter' || e.code === 'Space') {
      this.toggleIsOpenAttribute()
    }
  }

  private toggleIsOpenAttribute() {
    const menu = this.shadowRoot?.querySelector('.popover-menu')
    if (menu?.getAttribute('is-open') === null) {
      menu?.setAttribute('is-open', 'true')
    }
  }

  async firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties)
    const applications = await this.getApplications()
    this.setAppSwitcherMenuItems(applications)
    if (applications.length > 0) {
      this.subscribeToLocaleChange(applications)
    }
  }

  private subscribeToLocaleChange(applications: readonly ApplicationItem[]) {
    const { localeChanged } = getEventBus().subscribeTo<typeof I18NNamespace, typeof I18NEvents>(I18NNamespace)
    const onLocaleChange = () => {
      this.setAppSwitcherMenuItems(applications)
    }
    localeChanged.on(onLocaleChange)

    this.unsubscribeFunctions.push(() => {
      localeChanged.removeListener(onLocaleChange)
    })
  }

  private setAppSwitcherMenuItems(applications: readonly ApplicationItem[]) {
    this.appSwitcherMenuItems = this.getAppSwitcherMenuItems(applications)
  }

  private async getApplications() {
    let applications: readonly ApplicationItem[] = []

    try {
      // TODO Remove this 'if' in SCORE-1220
      // We need to bypass this auth because jiveApiBaseUrl in RC is not deployed yet.
      if (environment().environment === 'rc') {
        throw 'rc not set up'
      }

      const response = await authenticatedFetch<ApplicationsResponse>(
        `${getJiveApiBaseUrl()}/applications/v1/applications`,
        {
          credentials: undefined,
        },
      )
      const applicationItems = (await response.json()).items
      applications = Array.isArray(applicationItems) ? applicationItems : []
    } catch (e) {
      getShellLogger().error('could not fetch Applications', e)
    }
    return applications
  }

  private getAppSwitcherMenuItems(applications: readonly ApplicationItem[]): readonly PopoverMenu[] {
    return applications.map((applicationsItem: ApplicationItem) => ({
      text: this.getLocalizedAppName(applicationsItem),
      href: applicationsItem.url,
      isExternalLink: true,
      dataTest: applicationsItem.id.toLowerCase(),
      origin: 'App Switcher',
    }))
  }

  private getLocalizedAppName(applicationsItem: ApplicationItem) {
    const selectedLocale = getCurrentLocale()
    const getLocalizedDetails = (locale: Locale) =>
      applicationsItem.localizedDetails.find(element => element.language.replace('-', '_') === locale)

    const localizedDetails = getLocalizedDetails(selectedLocale) ?? applicationsItem.localizedDetails[0]
    return localizedDetails.name
  }

  render() {
    return this.appSwitcherMenuItems.length > 0
      ? html` <chameleon-typography
            class="menu-trigger"
            id="app-switcher-trigger"
            aria-label=${t('App Switcher')}
            data-test="app-switcher"
            tag="p"
            variant="body-small"
          >
            <chameleon-svg> ${unsafeSVG(SVG_APP_SWITCHER_FILLED)}</chameleon-svg>
          </chameleon-typography>
          <chameleon-popover-v2
            class="popover-menu"
            position="right-end"
            arrow=""
            menu
            z-index="70"
            trigger-id="app-switcher-trigger"
            label=${t('App Switcher Popover')}
          >
            <goto-popover-menu .menuItems=${this.appSwitcherMenuItems} class="app-switcher-popover-menu">
            </goto-popover-menu>
          </chameleon-popover-v2>`
      : nothing
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly 'goto-app-switcher': GoToAppSwitcher
  }
}
